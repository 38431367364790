import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Divider,
    Button,
    TextField,
    InputAdornment,
    Chip,
    FormControlLabel,
    Switch,
    Paper,
    Alert,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {
    Search as SearchIcon,
    Layers as LayersIcon,
    CheckCircle as CheckCircleIcon,
    FilterList as FilterListIcon,
    VisibilityOff as VisibilityOffIcon,
    Visibility as VisibilityIcon
} from '@mui/icons-material';

const LayerSelector = ({ drawing, selectedLayers, setSelectedLayers }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showStats, setShowStats] = useState(false);
    const [showEmptyLayers, setShowEmptyLayers] = useState(false);

    // Extract layers from drawing
    const layers = useMemo(() => {
        if (!drawing || !drawing.entities) return [];

        // If drawing has metadata with layers, use that
        if (drawing.metadata && drawing.metadata.layers) {
            return drawing.metadata.layers.map(layer => {
                // Count entities in this layer
                const entityCount = drawing.entities.filter(e => e.layer === layer.name).length;
                return {
                    ...layer,
                    entityCount
                };
            });
        }

        // Otherwise, extract unique layers from entities
        const layerMap = new Map();
        drawing.entities.forEach(entity => {
            const layerName = entity.layer || '0';
            if (!layerMap.has(layerName)) {
                layerMap.set(layerName, {
                    name: layerName,
                    color: entity.layer_color || '#FFFFFF',
                    entityCount: 1
                });
            } else {
                const layer = layerMap.get(layerName);
                layer.entityCount += 1;
            }
        });

        return Array.from(layerMap.values());
    }, [drawing]);

    // Filter layers based on search term and empty layer filter
    const filteredLayers = useMemo(() => {
        if (!layers) return [];

        return layers.filter(layer => {
            // Filter by search term
            const matchesSearch = layer.name.toLowerCase().includes(searchTerm.toLowerCase());

            // Filter empty layers if option is disabled
            const matchesEmpty = showEmptyLayers || layer.entityCount > 0;

            return matchesSearch && matchesEmpty;
        });
    }, [layers, searchTerm, showEmptyLayers]);

    // Calculate statistics
    const stats = useMemo(() => {
        if (!layers || !drawing) return { totalEntities: 0, selectedEntities: 0, percentage: 0 };

        const totalEntities = drawing.entities.length;
        const selectedEntities = drawing.entities.filter(entity =>
            selectedLayers.includes(entity.layer)
        ).length;

        return {
            totalEntities,
            selectedEntities,
            percentage: totalEntities > 0 ? Math.round((selectedEntities / totalEntities) * 100) : 0
        };
    }, [drawing, selectedLayers]);

    // Handle layer selection toggle
    const handleToggleLayer = (layerName) => {
        setSelectedLayers(prev => {
            if (prev.includes(layerName)) {
                return prev.filter(name => name !== layerName);
            } else {
                return [...prev, layerName];
            }
        });
    };

    // Handle select/deselect all
    const handleSelectAll = () => {
        setSelectedLayers(filteredLayers.map(layer => layer.name));
    };

    const handleDeselectAll = () => {
        setSelectedLayers([]);
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Select Layers
            </Typography>

            <Typography variant="body1" paragraph>
                Choose which layers you want to include in your configured drawing.
                Selecting only the layers you need will improve performance and reduce file size.
            </Typography>

            {/* Statistics Card */}
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <Typography variant="h6" color="primary" gutterBottom>
                        Selection Summary
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Box textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Total Entities
                                </Typography>
                                <Typography variant="h4">
                                    {stats.totalEntities.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Selected Entities
                                </Typography>
                                <Typography variant="h4">
                                    {stats.selectedEntities.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box textAlign="center">
                                <Typography variant="body2" color="text.secondary">
                                    Percentage
                                </Typography>
                                <Typography variant="h4" color={stats.percentage < 30 ? 'success.main' : 'warning.main'}>
                                    {stats.percentage}%
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }}>
                        <Alert severity={stats.percentage < 30 ? 'success' : stats.percentage < 60 ? 'warning' : 'error'}>
                            {stats.percentage < 30 ?
                                'Great! You\'ve selected a small subset of entities, which will optimize performance.' :
                                stats.percentage < 60 ?
                                    'Consider reducing your selection further for better performance.' :
                                    'You\'ve selected most entities. Try reducing your selection for optimal performance.'}
                        </Alert>
                    </Box>
                </CardContent>
            </Card>

            {/* Search and Filters */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    fullWidth
                    placeholder="Search layers..."
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ mr: 2 }}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={showEmptyLayers}
                            onChange={(e) => setShowEmptyLayers(e.target.checked)}
                            size="small"
                        />
                    }
                    label="Show Empty Layers"
                />
            </Box>

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleSelectAll}
                >
                    Select All
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleDeselectAll}
                >
                    Deselect All
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setShowStats(!showStats)}
                >
                    {showStats ? 'Hide Statistics' : 'Show Statistics'}
                </Button>
            </Box>

            {/* Error when no layers */}
            {layers.length === 0 && (
                <Alert severity="error">
                    No layers found in this drawing. There might be an issue with the DXF file.
                </Alert>
            )}

            {/* Statistics Table */}
            {showStats && (
                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Layer Statistics
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Layer Name</TableCell>
                                    <TableCell align="right">Entity Count</TableCell>
                                    <TableCell align="right">Percentage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {layers
                                    .sort((a, b) => b.entityCount - a.entityCount)
                                    .map(layer => (
                                        <TableRow key={layer.name}>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Box
                                                        sx={{
                                                            width: 16,
                                                            height: 16,
                                                            bgcolor: layer.color || '#FFFFFF',
                                                            border: '1px solid #ccc',
                                                            mr: 1
                                                        }}
                                                    />
                                                    {layer.name}
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right">{layer.entityCount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round((layer.entityCount / stats.totalEntities) * 100)}%
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}

            {/* Layers List */}
            <Paper variant="outlined" sx={{ maxHeight: 400, overflow: 'auto' }}>
                <List>
                    {filteredLayers.length === 0 ? (
                        <ListItem>
                            <ListItemText primary="No layers match your search criteria." />
                        </ListItem>
                    ) : (
                        filteredLayers.map(layer => (
                            <React.Fragment key={layer.name}>
                                <ListItem
                                    button
                                    onClick={() => handleToggleLayer(layer.name)}
                                    selected={selectedLayers.includes(layer.name)}
                                    sx={{
                                        bgcolor: selectedLayers.includes(layer.name) ? 'action.selected' : 'inherit',
                                        '&:hover': {
                                            bgcolor: selectedLayers.includes(layer.name) ? 'action.selected' : 'action.hover',
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectedLayers.includes(layer.name)}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box
                                                    sx={{
                                                        width: 16,
                                                        height: 16,
                                                        bgcolor: layer.color || '#FFFFFF',
                                                        border: '1px solid #ccc',
                                                        mr: 1
                                                    }}
                                                />
                                                {layer.name}
                                            </Box>
                                        }
                                        secondary={`${layer.entityCount} entities`}
                                    />
                                    {layer.frozen && (
                                        <Chip size="small" label="Frozen" sx={{ mr: 1 }} />
                                    )}
                                    {layer.entityCount === 0 && (
                                        <Chip size="small" label="Empty" color="warning" sx={{ mr: 1 }} />
                                    )}
                                    {selectedLayers.includes(layer.name) ? (
                                        <VisibilityIcon color="primary" />
                                    ) : (
                                        <VisibilityOffIcon color="disabled" />
                                    )}
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))
                    )}
                </List>
            </Paper>

            <Box sx={{ mt: 3 }}>
                <Alert severity="info">
                    <Typography variant="subtitle2">
                        Selected {selectedLayers.length} of {layers.length} layers ({stats.selectedEntities} entities)
                    </Typography>
                    In the next step, you'll define work areas using elements from these selected layers.
                </Alert>
            </Box>
        </Box>
    );
};

LayerSelector.propTypes = {
    drawing: PropTypes.object.isRequired,
    selectedLayers: PropTypes.array.isRequired,
    setSelectedLayers: PropTypes.func.isRequired
};

export default LayerSelector;
