import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Chip,
    Alert,
    Card,
    CardContent,
    InputAdornment,
    Tooltip,
    CircularProgress,
    FormHelperText,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Room as RoomIcon,
    Timeline as TimelineIcon,
    Category as CategoryIcon,
    Straighten as StraightenIcon,
    SquareFoot as SquareFootIcon,
} from '@mui/icons-material';
import { Stage, Layer, Line, Circle, Group, Text, Rect } from 'react-konva';

const WORK_AREA_CATEGORIES = [
    { value: 'WALL', label: 'Walls' },
    { value: 'FLOOR', label: 'Floors' },
    { value: 'CEILING', label: 'Ceilings' },
    { value: 'PLUMBING', label: 'Plumbing' },
    { value: 'ELECTRICAL', label: 'Electrical' },
    { value: 'HVAC', label: 'HVAC' },
    { value: 'STRUCTURAL', label: 'Structural' },
    { value: 'GENERAL', label: 'General' },
];

const MEASUREMENT_UNITS = [
    { value: 'm', label: 'Meters' },
    { value: 'ft', label: 'Feet' },
    { value: 'cm', label: 'Centimeters' },
    { value: 'mm', label: 'Millimeters' },
];

const WorkAreaDefiner = ({ drawing, selectedLayers, workAreas, setWorkAreas }) => {
    const [selectedElements, setSelectedElements] = useState([]);
    const [hoveredElement, setHoveredElement] = useState(null);
    const [selectedWorkArea, setSelectedWorkArea] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dimensionsDialogOpen, setDimensionsDialogOpen] = useState(false);
    const [isSelectionMode, setIsSelectionMode] = useState(false);

    // Canvas state
    const [scale, setScale] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [viewportSize, setViewportSize] = useState({ width: 600, height: 400 });
    const containerRef = useRef(null);
    const stageRef = useRef(null);

    // Form state
    const [workAreaForm, setWorkAreaForm] = useState({
        name: '',
        category: 'GENERAL',
        description: '',
        element_ids: [],
    });

    const [dimensionsForm, setDimensionsForm] = useState({
        length: '',
        area: '',
        unit: 'm',
    });

    const [formErrors, setFormErrors] = useState({});

    // Filter elements based on selected layers
    const visibleElements = drawing?.entities?.filter(entity =>
        selectedLayers.includes(entity.layer)
    ) || [];

    // Calculate bounds of drawing
    const calculateBounds = () => {
        if (!visibleElements.length) return { minX: 0, minY: 0, maxX: 100, maxY: 100 };

        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        visibleElements.forEach(entity => {
            // Calculate bounds based on entity type
            if (entity.points) {
                entity.points.forEach(point => {
                    minX = Math.min(minX, point.x);
                    minY = Math.min(minY, point.y);
                    maxX = Math.max(maxX, point.x);
                    maxY = Math.max(maxY, point.y);
                });
            } else if (entity.center && entity.radius) {
                const { center, radius } = entity;
                minX = Math.min(minX, center.x - radius);
                minY = Math.min(minY, center.y - radius);
                maxX = Math.max(maxX, center.x + radius);
                maxY = Math.max(maxY, center.y + radius);
            } else if (entity.position) {
                const { position } = entity;
                minX = Math.min(minX, position.x);
                minY = Math.min(minY, position.y);
                maxX = Math.max(maxX, position.x);
                maxY = Math.max(maxY, position.y);
            }
        });

        // Add padding
        const paddingPercentage = 0.05;
        const paddingX = (maxX - minX) * paddingPercentage;
        const paddingY = (maxY - minY) * paddingPercentage;

        return {
            minX: minX - paddingX,
            minY: minY - paddingY,
            maxX: maxX + paddingX,
            maxY: maxY + paddingY,
        };
    };

    // Adjust viewport when component mounts or container size changes
    useEffect(() => {
        const updateViewportSize = () => {
            if (containerRef.current) {
                const { clientWidth, clientHeight } = containerRef.current;
                setViewportSize({
                    width: clientWidth,
                    height: clientHeight,
                });
            }
        };

        updateViewportSize();
        window.addEventListener('resize', updateViewportSize);

        return () => {
            window.removeEventListener('resize', updateViewportSize);
        };
    }, []);

    // Fit drawing to viewport when data changes
    useEffect(() => {
        if (visibleElements.length > 0 && viewportSize.width > 0) {
            fitDrawingToViewport();
        }
    }, [visibleElements, viewportSize]);

    // Fit drawing to viewport
    const fitDrawingToViewport = () => {
        const bounds = calculateBounds();
        const { minX, minY, maxX, maxY } = bounds;

        const drawingWidth = maxX - minX;
        const drawingHeight = maxY - minY;

        // Calculate scale to fit drawing in viewport with some padding
        const scaleX = viewportSize.width / drawingWidth * 0.9;
        const scaleY = viewportSize.height / drawingHeight * 0.9;
        const newScale = Math.min(scaleX, scaleY);

        // Calculate position to center drawing
        const drawingCenterX = (minX + maxX) / 2;
        const drawingCenterY = (minY + maxY) / 2;

        const newPosition = {
            x: viewportSize.width / 2 - drawingCenterX * newScale,
            y: viewportSize.height / 2 - drawingCenterY * newScale,
        };

        setScale(newScale);
        setPosition(newPosition);
    };

    // Handle mouse wheel for zooming
    const handleWheel = (e) => {
        e.evt.preventDefault();

        const pointer = stageRef.current.getPointerPosition();
        const mousePointTo = {
            x: (pointer.x - position.x) / scale,
            y: (pointer.y - position.y) / scale,
        };

        // Calculate new scale (zoom in/out)
        const scaleBy = 1.1;
        const newScale = e.evt.deltaY < 0 ? scale * scaleBy : scale / scaleBy;

        // Limit scale
        const limitedScale = Math.max(0.01, Math.min(50, newScale));

        // Calculate new position
        const newPos = {
            x: pointer.x - mousePointTo.x * limitedScale,
            y: pointer.y - mousePointTo.y * limitedScale,
        };

        setScale(limitedScale);
        setPosition(newPos);
    };

    // Handle element click
    const handleElementClick = (element) => {
        if (isSelectionMode) {
            // Toggle element selection
            setSelectedElements(prev => {
                if (prev.includes(element.id)) {
                    return prev.filter(id => id !== element.id);
                } else {
                    return [...prev, element.id];
                }
            });

            // Update form
            if (isEditMode) {
                setWorkAreaForm(prev => {
                    const currentIds = prev.element_ids || [];
                    if (currentIds.includes(element.id)) {
                        return {
                            ...prev,
                            element_ids: currentIds.filter(id => id !== element.id),
                        };
                    } else {
                        return {
                            ...prev,
                            element_ids: [...currentIds, element.id],
                        };
                    }
                });
            }
        }
    };

    // Calculate element color based on selection status
    const getElementColor = (element) => {
        if (hoveredElement === element.id) {
            return '#4CAF50'; // Green for hover
        }

        if (selectedElements.includes(element.id)) {
            return '#2196F3'; // Blue for selected
        }

        // Check if element is in any work area
        const isInWorkArea = workAreas.some(area =>
            area.element_ids && area.element_ids.includes(element.id)
        );

        if (isInWorkArea) {
            return '#9C27B0'; // Purple for elements in work areas
        }

        return '#AAAAAA'; // Default gray
    };

    // Render an element (simplified for various entity types)
    const renderElement = (entity) => {
        const color = getElementColor(entity);
        const strokeWidth = 1 / scale;
        const hitStrokeWidth = 10 / scale;

        // Render based on entity type
        if (entity.type === 'LINE' && entity.points && entity.points.length >= 2) {
            return (
                <Line
                    key={entity.id}
                    points={entity.points.flatMap(p => [p.x, p.y])}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    onClick={() => handleElementClick(entity)}
                    onMouseEnter={() => setHoveredElement(entity.id)}
                    onMouseLeave={() => setHoveredElement(null)}
                />
            );
        }

        if (entity.type === 'POLYLINE' && entity.points && entity.points.length >= 2) {
            return (
                <Line
                    key={entity.id}
                    points={entity.points.flatMap(p => [p.x, p.y])}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    closed={entity.closed}
                    onClick={() => handleElementClick(entity)}
                    onMouseEnter={() => setHoveredElement(entity.id)}
                    onMouseLeave={() => setHoveredElement(null)}
                />
            );
        }

        if (entity.type === 'CIRCLE' && entity.center && entity.radius) {
            return (
                <Circle
                    key={entity.id}
                    x={entity.center.x}
                    y={entity.center.y}
                    radius={entity.radius}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    onClick={() => handleElementClick(entity)}
                    onMouseEnter={() => setHoveredElement(entity.id)}
                    onMouseLeave={() => setHoveredElement(null)}
                />
            );
        }

        if (entity.type === 'TEXT' && entity.position && entity.text) {
            return (
                <Group
                    key={entity.id}
                    onClick={() => handleElementClick(entity)}
                    onMouseEnter={() => setHoveredElement(entity.id)}
                    onMouseLeave={() => setHoveredElement(null)}
                >
                    <Text
                        x={entity.position.x}
                        y={entity.position.y}
                        text={entity.text}
                        fill={color}
                        fontSize={entity.height || 2}
                    />
                    <Rect
                        x={entity.position.x - 2}
                        y={entity.position.y - 2}
                        width={(entity.text.length * (entity.height || 2)) + 4}
                        height={(entity.height || 2) + 4}
                        stroke={color}
                        strokeWidth={strokeWidth}
                    />
                </Group>
            );
        }

        // Fallback for other types
        if (entity.points && entity.points.length > 0) {
            return (
                <Line
                    key={entity.id}
                    points={entity.points.flatMap(p => [p.x, p.y])}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    onClick={() => handleElementClick(entity)}
                    onMouseEnter={() => setHoveredElement(entity.id)}
                    onMouseLeave={() => setHoveredElement(null)}
                />
            );
        }

        return null;
    };

    // Handle opening add work area dialog
    const handleAddWorkArea = () => {
        setIsEditMode(false);
        setSelectedWorkArea(null);
        setWorkAreaForm({
            name: '',
            category: 'GENERAL',
            description: '',
            element_ids: selectedElements,
        });
        setFormErrors({});
        setDialogOpen(true);
    };

    // Handle opening edit work area dialog
    const handleEditWorkArea = (workArea) => {
        setIsEditMode(true);
        setSelectedWorkArea(workArea);
        setWorkAreaForm({
            name: workArea.name,
            category: workArea.category,
            description: workArea.description || '',
            element_ids: workArea.element_ids || [],
        });
        setSelectedElements(workArea.element_ids || []);
        setFormErrors({});
        setDialogOpen(true);
    };

    // Handle opening dimensions dialog
    const handleOpenDimensionsDialog = (workArea) => {
        setSelectedWorkArea(workArea);

        // Initialize form with existing values
        setDimensionsForm({
            length: workArea.real_dimensions?.length || '',
            area: workArea.real_dimensions?.area || '',
            unit: workArea.real_dimensions?.unit || 'm',
        });

        setDimensionsDialogOpen(true);
    };

    // Handle saving dimensions
    const handleSaveDimensions = () => {
        const { length, area, unit } = dimensionsForm;

        // Validate
        if (!length && !area) {
            setFormErrors({
                length: 'At least one dimension is required',
            });
            return;
        }

        if (length && isNaN(parseFloat(length))) {
            setFormErrors({
                length: 'Length must be a number',
            });
            return;
        }

        if (area && isNaN(parseFloat(area))) {
            setFormErrors({
                area: 'Area must be a number',
            });
            return;
        }

        // Update work area
        const updatedWorkAreas = workAreas.map(workArea => {
            if (workArea.id === selectedWorkArea.id) {
                const real_dimensions = {
                    unit,
                };

                if (length) real_dimensions.length = parseFloat(length);
                if (area) real_dimensions.area = parseFloat(area);

                return {
                    ...workArea,
                    real_dimensions,
                };
            }
            return workArea;
        });

        setWorkAreas(updatedWorkAreas);
        setDimensionsDialogOpen(false);
        setFormErrors({});
    };

    // Handle saving work area
    const handleSaveWorkArea = () => {
        // Validate form
        const errors = {};
        if (!workAreaForm.name.trim()) {
            errors.name = 'Name is required';
        }

        if (!workAreaForm.element_ids.length) {
            errors.elements = 'At least one element must be selected';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        if (isEditMode && selectedWorkArea) {
            // Update existing work area
            const updatedWorkAreas = workAreas.map(area => {
                if (area.id === selectedWorkArea.id) {
                    return {
                        ...area,
                        name: workAreaForm.name,
                        category: workAreaForm.category,
                        description: workAreaForm.description,
                        element_ids: workAreaForm.element_ids,
                    };
                }
                return area;
            });

            setWorkAreas(updatedWorkAreas);
        } else {
            // Create new work area with unique id
            const newWorkArea = {
                id: `wa-${Date.now()}`, // Simple unique ID generation
                name: workAreaForm.name,
                category: workAreaForm.category,
                description: workAreaForm.description,
                element_ids: workAreaForm.element_ids,
                status: 'not_started',
            };

            setWorkAreas([...workAreas, newWorkArea]);
        }

        // Reset state
        setDialogOpen(false);
        setSelectedElements([]);
        setFormErrors({});
    };

    // Handle deleting work area
    const handleDeleteWorkArea = (workAreaId) => {
        const updatedWorkAreas = workAreas.filter(area => area.id !== workAreaId);
        setWorkAreas(updatedWorkAreas);
    };

    // Calculate metrics for a work area
    const calculateWorkAreaMetrics = (workAreaId) => {
        const workArea = workAreas.find(area => area.id === workAreaId);
        if (!workArea) return { elementCount: 0, types: {} };

        const elementIds = workArea.element_ids || [];
        const elements = visibleElements.filter(element => elementIds.includes(element.id));

        // Count by entity type
        const typeCount = elements.reduce((acc, element) => {
            const type = element.type || 'UNKNOWN';
            acc[type] = (acc[type] || 0) + 1;
            return acc;
        }, {});

        // Calculate total length if available in elements
        let totalLength = 0;
        elements.forEach(element => {
            if (element.metrics && element.metrics.length) {
                totalLength += element.metrics.length;
            }
        });

        return {
            elementCount: elements.length,
            types: typeCount,
            estimatedLength: totalLength > 0 ? totalLength.toFixed(2) : undefined,
        };
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Define Work Areas
            </Typography>

            <Typography variant="body1" paragraph>
                Define logical work areas by selecting elements from the drawing.
                Each work area will represent a specific part of the project (e.g., a wall, room, or section).
            </Typography>

            <Grid container spacing={3}>
                {/* Left side - Drawing canvas */}
                <Grid item xs={12} md={8}>
                    <Paper
                        variant="outlined"
                        sx={{
                            p: 1,
                            height: 500,
                            position: 'relative',
                            overflow: 'hidden'
                        }}
                        ref={containerRef}
                    >
                        {visibleElements.length === 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    flexDirection: 'column'
                                }}
                            >
                                <Alert severity="warning" sx={{ mb: 2 }}>
                                    No elements available. Please select at least one layer in the previous step.
                                </Alert>
                                <Button
                                    variant="contained"
                                    onClick={() => window.history.back()}
                                >
                                    Go Back
                                </Button>
                            </Box>
                        ) : (
                            <Stage
                                ref={stageRef}
                                width={viewportSize.width}
                                height={viewportSize.height}
                                onWheel={handleWheel}
                                draggable
                                onDragEnd={(e) => {
                                    setPosition({
                                        x: e.target.x(),
                                        y: e.target.y(),
                                    });
                                }}
                            >
                                <Layer>
                                    <Group x={position.x} y={position.y} scaleX={scale} scaleY={scale}>
                                        {visibleElements.map(renderElement)}
                                    </Group>
                                </Layer>
                            </Stage>
                        )}

                        {/* Controls */}
                        <Box sx={{
                            position: 'absolute',
                            bottom: 12,
                            right: 12,
                            display: 'flex',
                            bgcolor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 1,
                            p: 0.5
                        }}>
                            <Tooltip title="Zoom In">
                                <IconButton size="small" onClick={() => setScale(scale * 1.2)}>
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Zoom Out">
                                <IconButton size="small" onClick={() => setScale(scale / 1.2)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Fit Drawing">
                                <IconButton size="small" onClick={fitDrawingToViewport}>
                                    <TimelineIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        {/* Selection Mode Toggle */}
                        <Box sx={{
                            position: 'absolute',
                            top: 12,
                            left: 12,
                            display: 'flex',
                            bgcolor: 'rgba(255, 255, 255, 0.8)',
                            borderRadius: 1,
                            p: 1
                        }}>
                            <Button
                                variant={isSelectionMode ? "contained" : "outlined"}
                                color="primary"
                                size="small"
                                onClick={() => setIsSelectionMode(!isSelectionMode)}
                                startIcon={isSelectionMode ? <SaveIcon /> : <RoomIcon />}
                            >
                                {isSelectionMode ? "End Selection" : "Start Selection"}
                            </Button>

                            {isSelectionMode && (
                                <Box sx={{ ml: 1 }}>
                                    <Chip
                                        label={`${selectedElements.length} elements selected`}
                                        color="primary"
                                        variant="outlined"
                                    />
                                </Box>
                            )}
                        </Box>
                    </Paper>

                    {/* Actions */}
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={selectedElements.length === 0 || !isSelectionMode}
                            onClick={handleAddWorkArea}
                            startIcon={<AddIcon />}
                        >
                            Create Work Area
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={() => setSelectedElements([])}
                            disabled={selectedElements.length === 0 || !isSelectionMode}
                        >
                            Clear Selection
                        </Button>
                    </Box>

                    {/* Selection Instructions */}
                    {isSelectionMode && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            Click on elements in the drawing to select them. Selected elements will appear blue.
                            Once you've selected all elements for a work area, click "Create Work Area".
                        </Alert>
                    )}
                </Grid>

                {/* Right side - Work Areas list */}
                <Grid item xs={12} md={4}>
                    <Paper variant="outlined" sx={{ height: '100%', p: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Work Areas
                            <Chip
                                label={workAreas.length}
                                color="primary"
                                size="small"
                                sx={{ ml: 1 }}
                            />
                        </Typography>

                        {workAreas.length === 0 ? (
                            <Alert severity="info" sx={{ mt: 2 }}>
                                No work areas defined yet. Select elements from the drawing and create your first work area.
                            </Alert>
                        ) : (
                            <List>
                                {workAreas.map((workArea) => {
                                    const metrics = calculateWorkAreaMetrics(workArea.id);

                                    return (
                                        <React.Fragment key={workArea.id}>
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    // Highlight elements in this work area
                                                    setSelectedElements(workArea.element_ids || []);
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="subtitle2">
                                                                {workArea.name}
                                                            </Typography>
                                                            <Chip
                                                                label={workArea.category}
                                                                size="small"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <Box>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {metrics.elementCount} elements
                                                            </Typography>

                                                            {workArea.real_dimensions?.length && (
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Length: {workArea.real_dimensions.length} {workArea.real_dimensions.unit}
                                                                </Typography>
                                                            )}

                                                            {workArea.real_dimensions?.area && (
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Area: {workArea.real_dimensions.area} {workArea.real_dimensions.unit}²
                                                                </Typography>
                                                            )}

                                                            {!workArea.real_dimensions && metrics.estimatedLength && (
                                                                <Typography variant="body2" color="warning.main">
                                                                    Estimated length: {metrics.estimatedLength} (needs calibration)
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="Set Real Dimensions">
                                                        <IconButton
                                                            edge="end"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleOpenDimensionsDialog(workArea);
                                                            }}
                                                            size="small"
                                                        >
                                                            <StraightenIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                        <IconButton
                                                            edge="end"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditWorkArea(workArea);
                                                            }}
                                                            size="small"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            edge="end"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteWorkArea(workArea.id);
                                                            }}
                                                            size="small"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })}
                            </List>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Work Area Summary Card */}
            {workAreas.length > 0 && (
                <Card sx={{ mt: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Work Areas Summary
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                                    <Typography variant="h3">{workAreas.length}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Total Work Areas
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={4}>
                                <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                                    <Typography variant="h3">
                                        {workAreas.reduce((total, area) => total + (area.element_ids?.length || 0), 0)}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Total Elements
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={4}>
                                <Paper variant="outlined" sx={{ p: 2, textAlign: 'center' }}>
                                    <Typography variant="h3">
                                        {Object.keys(workAreas.reduce((acc, area) => {
                                            acc[area.category] = true;
                                            return acc;
                                        }, {})).length}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Categories
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Alert severity="success" sx={{ mt: 2 }}>
                            {workAreas.length > 0 && workAreas.every(area => area.real_dimensions) ? (
                                "All work areas have real-world dimensions defined. Ready for the next step!"
                            ) : (
                                "Don't forget to set real-world dimensions for each work area for accurate cost calculation."
                            )}
                        </Alert>
                    </CardContent>
                </Card>
            )}

            {/* Add/Edit Work Area Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {isEditMode ? "Edit Work Area" : "Create New Work Area"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                value={workAreaForm.name}
                                onChange={(e) => setWorkAreaForm({ ...workAreaForm, name: e.target.value })}
                                error={!!formErrors.name}
                                helperText={formErrors.name}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    value={workAreaForm.category}
                                    label="Category"
                                    onChange={(e) => setWorkAreaForm({ ...workAreaForm, category: e.target.value })}
                                >
                                    {WORK_AREA_CATEGORIES.map(category => (
                                        <MenuItem key={category.value} value={category.value}>
                                            {category.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
                                <Typography variant="body2" color="text.secondary">
                                    Selected Elements:
                                </Typography>
                                <Typography variant="h6">
                                    {workAreaForm.element_ids?.length || 0}
                                </Typography>
                                {formErrors.elements && (
                                    <Typography variant="caption" color="error">
                                        {formErrors.elements}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                multiline
                                rows={2}
                                value={workAreaForm.description}
                                onChange={(e) => setWorkAreaForm({ ...workAreaForm, description: e.target.value })}
                            />
                        </Grid>
                    </Grid>

                    {isEditMode && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            You can select or deselect elements in the drawing to update this work area.
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveWorkArea} variant="contained" color="primary">
                        {isEditMode ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Set Real Dimensions Dialog */}
            <Dialog
                open={dimensionsDialogOpen}
                onClose={() => setDimensionsDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Set Real Dimensions for {selectedWorkArea?.name}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                        Define real-world dimensions for accurate cost calculation and reporting.
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Length"
                                placeholder="e.g. 5.5"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {dimensionsForm.unit}
                                    </InputAdornment>,
                                }}
                                value={dimensionsForm.length}
                                onChange={(e) => setDimensionsForm({ ...dimensionsForm, length: e.target.value })}
                                error={!!formErrors.length}
                                helperText={formErrors.length}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Area"
                                placeholder="e.g. 25.4"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {dimensionsForm.unit}²
                                    </InputAdornment>,
                                }}
                                value={dimensionsForm.area}
                                onChange={(e) => setDimensionsForm({ ...dimensionsForm, area: e.target.value })}
                                error={!!formErrors.area}
                                helperText={formErrors.area}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Unit</InputLabel>
                                <Select
                                    value={dimensionsForm.unit}
                                    label="Unit"
                                    onChange={(e) => setDimensionsForm({ ...dimensionsForm, unit: e.target.value })}
                                >
                                    {MEASUREMENT_UNITS.map(unit => (
                                        <MenuItem key={unit.value} value={unit.value}>
                                            {unit.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>
                                    Select the unit of measurement
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Alert severity="info" sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            <strong>Tip:</strong> Enter at least one measurement. Length is appropriate for linear elements like walls,
                            while area is suitable for floors, ceilings, and surfaces.
                        </Typography>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDimensionsDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveDimensions} variant="contained" color="primary">
                        Save Dimensions
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

WorkAreaDefiner.propTypes = {
    drawing: PropTypes.object.isRequired,
    selectedLayers: PropTypes.array.isRequired,
    workAreas: PropTypes.array.isRequired,
    setWorkAreas: PropTypes.func.isRequired
};

export default WorkAreaDefiner;
