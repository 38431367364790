import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Divider,
    List,
    ListItem,
    ListItemText,
    Chip,
    Alert,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    FormControlLabel,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip
} from '@mui/material';
import {
    InfoOutlined as InfoIcon,
    CheckCircle as CheckCircleIcon,
    Warning as WarningIcon,
    Error as ErrorIcon,
    ExpandMore as ExpandMoreIcon,
    AccountTree as AccountTreeIcon,
    Analytics as AnalyticsIcon,
    BarChart as BarChartIcon,
    Layers as LayersIcon,
    Construction as ConstructionIcon,
    WorkOutline as WorkOutlineIcon,
    CalendarToday as CalendarTodayIcon,
    Search as SearchIcon,
    Hub as HubIcon,
    SettingsEthernet as SettingsEthernetIcon,
    Compress as CompressIcon,
    Code as CodeIcon
} from '@mui/icons-material';

const ConfigurationSummary = ({ drawing, selectedLayers, workAreas, workTypes }) => {
    const [showRawData, setShowRawData] = useState(false);
    const [showEntities, setShowEntities] = useState(false);
    const [expandedSection, setExpandedSection] = useState('overview');
    const [searchTerm, setSearchTerm] = useState('');

    // Calculate metrics for layers
    const layerMetrics = useMemo(() => {
        if (!drawing?.entities) return { total: 0, selected: 0, percentage: 0, byLayer: {} };

        const total = drawing.entities.length;
        const selected = drawing.entities.filter(entity =>
            selectedLayers.includes(entity.layer)
        ).length;

        const percentage = total > 0 ? (selected / total) * 100 : 0;

        // Entity counts by layer
        const byLayer = {};
        drawing.entities.forEach(entity => {
            const layer = entity.layer || 'unknown';
            if (!byLayer[layer]) {
                byLayer[layer] = { total: 0, selected: false };
            }
            byLayer[layer].total++;
            if (selectedLayers.includes(layer)) {
                byLayer[layer].selected = true;
            }
        });

        return { total, selected, percentage, byLayer };
    }, [drawing, selectedLayers]);

    // Calculate validation status
    const validationStatus = useMemo(() => {
        const results = {
            layers: { status: 'error', message: 'No layers selected' },
            workAreas: { status: 'error', message: 'No work areas defined' },
            workTypes: { status: 'error', message: 'No work types defined' },
            assignments: { status: 'error', message: 'No work types assigned to work areas' },
            dimensions: { status: 'error', message: 'Missing real dimensions for work areas' },
            overall: { status: 'error', message: 'Configuration is not complete' },
        };

        // Validate layers
        if (selectedLayers.length > 0) {
            results.layers.status = 'success';
            results.layers.message = `${selectedLayers.length} layers selected`;
        }

        // Validate work areas
        if (workAreas.length > 0) {
            results.workAreas.status = 'success';
            results.workAreas.message = `${workAreas.length} work areas defined`;
        }

        // Validate work types
        if (workTypes.length > 0) {
            results.workTypes.status = 'success';
            results.workTypes.message = `${workTypes.length} work types defined`;
        }

        // Validate assignments
        const workAreasWithTypes = workAreas.filter(area =>
            area.work_types && area.work_types.length > 0
        );

        if (workAreasWithTypes.length > 0) {
            if (workAreasWithTypes.length === workAreas.length) {
                results.assignments.status = 'success';
                results.assignments.message = 'All work areas have work types assigned';
            } else {
                results.assignments.status = 'warning';
                results.assignments.message = `${workAreasWithTypes.length} of ${workAreas.length} work areas have work types assigned`;
            }
        }

        // Validate dimensions
        const workAreasWithDimensions = workAreas.filter(area =>
            area.real_dimensions && (area.real_dimensions.length || area.real_dimensions.area)
        );

        if (workAreasWithDimensions.length > 0) {
            if (workAreasWithDimensions.length === workAreas.length) {
                results.dimensions.status = 'success';
                results.dimensions.message = 'All work areas have real dimensions defined';
            } else {
                results.dimensions.status = 'warning';
                results.dimensions.message = `${workAreasWithDimensions.length} of ${workAreas.length} work areas have real dimensions defined`;
            }
        }

        // Calculate overall status
        const statuses = Object.values(results).map(r => r.status).filter(s => s !== 'overall');

        if (statuses.every(s => s === 'success')) {
            results.overall.status = 'success';
            results.overall.message = 'Configuration is complete and ready to finalize';
        } else if (statuses.some(s => s === 'success') || statuses.some(s => s === 'warning')) {
            results.overall.status = 'warning';
            results.overall.message = 'Configuration has some issues that should be addressed';
        }

        return results;
    }, [selectedLayers, workAreas, workTypes]);

    // Calculate cost summary
    const costSummary = useMemo(() => {
        const summary = {
            totalCost: 0,
            totalPayment: 0,
            profit: 0,
            byCategory: {},
            byWorkArea: {},
        };

        // Calculate costs for each work area
        workAreas.forEach(workArea => {
            if (!workArea.work_types) return;

            let workAreaCost = 0;
            let workAreaPayment = 0;

            workArea.work_types.forEach(workType => {
                const typeDefinition = workTypes.find(wt => wt.id === workType.work_type_id);
                if (typeDefinition && workType.quantity) {
                    const cost = typeDefinition.cost_per_unit * workType.quantity;
                    const payment = typeDefinition.contractor_payment_per_unit * workType.quantity;

                    workAreaCost += cost;
                    workAreaPayment += payment;

                    // Accumulate by category
                    const category = typeDefinition.category || 'GENERAL';
                    if (!summary.byCategory[category]) {
                        summary.byCategory[category] = { cost: 0, payment: 0 };
                    }
                    summary.byCategory[category].cost += cost;
                    summary.byCategory[category].payment += payment;
                }
            });

            summary.totalCost += workAreaCost;
            summary.totalPayment += workAreaPayment;

            // Store work area totals
            summary.byWorkArea[workArea.id] = {
                name: workArea.name,
                category: workArea.category,
                cost: workAreaCost,
                payment: workAreaPayment,
                profit: workAreaCost - workAreaPayment,
            };
        });

        summary.profit = summary.totalCost - summary.totalPayment;

        return summary;
    }, [workAreas, workTypes]);

    // Calculate data reduction metrics
    const dataReductionMetrics = useMemo(() => {
        if (!drawing?.entities) return { original: 0, filtered: 0, reduction: 0 };

        const original = drawing.entities.length;

        // Count elements that will be in the lightweight version
        let filtered = 0;

        workAreas.forEach(workArea => {
            filtered += workArea.element_ids ? workArea.element_ids.length : 0;
        });

        // Calculate reduction percentage
        const reduction = original > 0 ? ((original - filtered) / original) * 100 : 0;

        return { original, filtered, reduction };
    }, [drawing, workAreas]);

    // Get category label from value
    const getCategoryLabel = (value) => {
        const categoryMap = {
            'WALL': 'Walls',
            'FLOOR': 'Floors',
            'CEILING': 'Ceilings',
            'PLUMBING': 'Plumbing',
            'ELECTRICAL': 'Electrical',
            'HVAC': 'HVAC',
            'STRUCTURAL': 'Structural',
            'FINISHING': 'Finishing',
            'DEMOLITION': 'Demolition',
            'INSULATION': 'Insulation',
            'GENERAL': 'General'
        };

        return categoryMap[value] || value;
    };

    // Filter work areas based on search term
    const filteredWorkAreas = useMemo(() => {
        if (!searchTerm) return workAreas;

        const search = searchTerm.toLowerCase();
        return workAreas.filter(area =>
            area.name.toLowerCase().includes(search) ||
            area.category.toLowerCase().includes(search) ||
            area.description?.toLowerCase().includes(search)
        );
    }, [workAreas, searchTerm]);

    // Filter work types based on search term
    const filteredWorkTypes = useMemo(() => {
        if (!searchTerm) return workTypes;

        const search = searchTerm.toLowerCase();
        return workTypes.filter(type =>
            type.name.toLowerCase().includes(search) ||
            type.category.toLowerCase().includes(search) ||
            type.description?.toLowerCase().includes(search)
        );
    }, [workTypes, searchTerm]);

    // Format currency
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    // Handle section expansion
    const handleSectionExpand = (section) => (event, isExpanded) => {
        setExpandedSection(isExpanded ? section : false);
    };

    // Render status icon
    const renderStatusIcon = (status) => {
        switch (status) {
            case 'success':
                return <CheckCircleIcon color="success" />;
            case 'warning':
                return <WarningIcon color="warning" />;
            case 'error':
                return <ErrorIcon color="error" />;
            default:
                return <InfoIcon color="info" />;
        }
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Configuration Summary
            </Typography>

            <Typography variant="body1" paragraph>
                Review your configuration before finalizing. This summary shows what will be
                included in your optimized drawing.
            </Typography>

            {/* Validation Status Card */}
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {renderStatusIcon(validationStatus.overall.status)}
                        <Typography variant="h6" sx={{ ml: 1 }}>
                            Configuration Status: {validationStatus.overall.message}
                        </Typography>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Configuration Steps
                                </Typography>

                                <List dense>
                                    <ListItem>
                                        <ListItemText
                                            primary="Layer Selection"
                                            secondary={validationStatus.layers.message}
                                        />
                                        {renderStatusIcon(validationStatus.layers.status)}
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary="Work Areas Definition"
                                            secondary={validationStatus.workAreas.message}
                                        />
                                        {renderStatusIcon(validationStatus.workAreas.status)}
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary="Work Types Definition"
                                            secondary={validationStatus.workTypes.message}
                                        />
                                        {renderStatusIcon(validationStatus.workTypes.status)}
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary="Work Type Assignments"
                                            secondary={validationStatus.assignments.message}
                                        />
                                        {renderStatusIcon(validationStatus.assignments.status)}
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText
                                            primary="Real Dimensions"
                                            secondary={validationStatus.dimensions.message}
                                        />
                                        {renderStatusIcon(validationStatus.dimensions.status)}
                                    </ListItem>
                                </List>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Data Reduction
                                </Typography>

                                <Box sx={{ mb: 2 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Typography variant="body2">
                                            Original Entity Count
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold">
                                            {dataReductionMetrics.original.toLocaleString()}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Typography variant="body2">
                                            Filtered Entity Count
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold">
                                            {dataReductionMetrics.filtered.toLocaleString()}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                        <Typography variant="body2">
                                            Reduction Percentage
                                        </Typography>
                                        <Typography variant="body2" fontWeight="bold" color="success.main">
                                            {dataReductionMetrics.reduction.toFixed(1)}%
                                        </Typography>
                                    </Box>
                                </Box>

                                <LinearProgress
                                    variant="determinate"
                                    value={dataReductionMetrics.reduction}
                                    color="success"
                                    sx={{ height: 10, borderRadius: 1 }}
                                />

                                <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
                                    Higher reduction percentage means better performance
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {validationStatus.overall.status === 'error' && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            Please address the issues above before finalizing the configuration.
                        </Alert>
                    )}

                    {validationStatus.overall.status === 'warning' && (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            There are some issues with your configuration, but you can proceed if needed.
                            For best results, consider addressing the warnings.
                        </Alert>
                    )}

                    {validationStatus.overall.status === 'success' && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Your configuration is complete and ready to be finalized. The drawing will be
                            converted to a lightweight format for optimal performance.
                        </Alert>
                    )}
                </CardContent>
            </Card>

            {/* Search and Filter */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    placeholder="Search work areas and types..."
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ mr: 2 }}
                />

                <FormControlLabel
                    control={
                        <Switch
                            checked={showRawData}
                            onChange={(e) => setShowRawData(e.target.checked)}
                            size="small"
                        />
                    }
                    label="Show Raw Data"
                />
            </Box>

            {/* Accordions for detailed sections */}
            <Accordion
                expanded={expandedSection === 'overview'}
                onChange={handleSectionExpand('overview')}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccountTreeIcon sx={{ mr: 1 }} /> Configuration Overview
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Drawing Information
                                </Typography>

                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Name</TableCell>
                                                <TableCell>{drawing?.name || drawing?.filename || 'Untitled Drawing'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">File</TableCell>
                                                <TableCell>{drawing?.filename || 'Unknown'}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Uploaded</TableCell>
                                                <TableCell>
                                                    {drawing?.upload_date ? new Date(drawing.upload_date).toLocaleString() : 'Unknown'}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Entity Count</TableCell>
                                                <TableCell>{drawing?.entities?.length?.toLocaleString() || 0}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Layer Count</TableCell>
                                                <TableCell>{Object.keys(layerMetrics.byLayer).length}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Configuration Counts
                                </Typography>

                                <TableContainer component={Paper} variant="outlined">
                                    <Table size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Selected Layers</TableCell>
                                                <TableCell>{selectedLayers.length}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Entities in Selected Layers</TableCell>
                                                <TableCell>{layerMetrics.selected.toLocaleString()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Work Areas</TableCell>
                                                <TableCell>{workAreas.length}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Work Types</TableCell>
                                                <TableCell>{workTypes.length}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Work Type Assignments</TableCell>
                                                <TableCell>
                                                    {workAreas.reduce((count, area) =>
                                                        count + (area.work_types ? area.work_types.length : 0), 0)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Alert severity="info">
                                <Typography variant="body2">
                                    <strong>What happens next?</strong> When you finalize this configuration, the system will:
                                </Typography>
                                <ul>
                                    <li>Filter out entities from non-selected layers</li>
                                    <li>Create a lightweight representation of work areas and elements</li>
                                    <li>Store work types and their costs for reporting</li>
                                    <li>Apply real-world dimensions for accurate measurements</li>
                                </ul>
                            </Alert>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedSection === 'layers'}
                onChange={handleSectionExpand('layers')}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <LayersIcon sx={{ mr: 1 }} /> Selected Layers
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ mb: 2 }}>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {selectedLayers.length === 0 ?
                                'No layers selected. Please go back to the first step to select layers.' :
                                `You've selected ${selectedLayers.length} layers containing ${layerMetrics.selected.toLocaleString()} entities (${layerMetrics.percentage.toFixed(1)}% of total entities).`
                            }
                        </Alert>

                        <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 300, overflow: 'auto' }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Layer Name</TableCell>
                                        <TableCell align="right">Entity Count</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(layerMetrics.byLayer)
                                        .sort(([, a], [, b]) => b.total - a.total) // Sort by entity count
                                        .map(([layerName, stats]) => (
                                            <TableRow key={layerName}>
                                                <TableCell>{layerName}</TableCell>
                                                <TableCell align="right">{stats.total.toLocaleString()}</TableCell>
                                                <TableCell align="center">
                                                    {stats.selected ? (
                                                        <Chip
                                                            label="Selected"
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    ) : (
                                                        <Chip
                                                            label="Not Selected"
                                                            color="default"
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedSection === 'workareas'}
                onChange={handleSectionExpand('workareas')}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <WorkOutlineIcon sx={{ mr: 1 }} /> Work Areas
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ mb: 2 }}>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {workAreas.length === 0 ?
                                'No work areas defined. Please go back to the second step to define work areas.' :
                                `You've defined ${workAreas.length} work areas across ${new Set(workAreas.map(a => a.category)).size} different categories.`
                            }
                        </Alert>

                        {filteredWorkAreas.length === 0 && searchTerm && (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                No work areas match your search term "{searchTerm}".
                            </Alert>
                        )}

                        {filteredWorkAreas.length > 0 && (
                            <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Work Area Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell align="right">Elements</TableCell>
                                            <TableCell align="right">Dimensions</TableCell>
                                            <TableCell align="right">Work Types</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredWorkAreas.map((workArea) => (
                                            <TableRow key={workArea.id}>
                                                <TableCell>{workArea.name}</TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={getCategoryLabel(workArea.category)}
                                                        size="small"
                                                        variant="outlined"
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {workArea.element_ids?.length || 0}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {workArea.real_dimensions ? (
                                                        <Box>
                                                            {workArea.real_dimensions.length && (
                                                                <Typography variant="body2">
                                                                    Length: {workArea.real_dimensions.length} {workArea.real_dimensions.unit}
                                                                </Typography>
                                                            )}
                                                            {workArea.real_dimensions.area && (
                                                                <Typography variant="body2">
                                                                    Area: {workArea.real_dimensions.area} {workArea.real_dimensions.unit}²
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    ) : (
                                                        <Chip
                                                            label="Missing"
                                                            color="error"
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {workArea.work_types?.length || 0}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedSection === 'worktypes'}
                onChange={handleSectionExpand('worktypes')}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <ConstructionIcon sx={{ mr: 1 }} /> Work Types
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ mb: 2 }}>
                        <Alert severity="info" sx={{ mb: 2 }}>
                            {workTypes.length === 0 ?
                                'No work types defined. Please go back to the third step to define work types.' :
                                `You've defined ${workTypes.length} work types with a total estimated project cost of ${formatCurrency(costSummary.totalCost)}.`
                            }
                        </Alert>

                        {filteredWorkTypes.length === 0 && searchTerm && (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                No work types match your search term "{searchTerm}".
                            </Alert>
                        )}

                        {filteredWorkTypes.length > 0 && (
                            <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Work Type Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell align="right">Cost per Unit</TableCell>
                                            <TableCell align="right">Contractor Payment</TableCell>
                                            <TableCell align="right">Profit Margin</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredWorkTypes.map((workType) => {
                                            const profitMargin = workType.cost_per_unit - workType.contractor_payment_per_unit;
                                            const profitPercentage = (profitMargin / workType.cost_per_unit) * 100;

                                            return (
                                                <TableRow key={workType.id}>
                                                    <TableCell>{workType.name}</TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={getCategoryLabel(workType.category)}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell>{workType.unit}</TableCell>
                                                    <TableCell align="right">
                                                        {formatCurrency(workType.cost_per_unit)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {formatCurrency(workType.contractor_payment_per_unit)}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <Typography variant="body2" color={profitMargin > 0 ? 'success.main' : 'error.main'}>
                                                                {formatCurrency(profitMargin)} ({profitPercentage.toFixed(1)}%)
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedSection === 'costs'}
                onChange={handleSectionExpand('costs')}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <BarChartIcon sx={{ mr: 1 }} /> Cost Analysis
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Total Project Cost
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <Box textAlign="center">
                                            <Typography variant="body2" color="text.secondary">
                                                Total Cost
                                            </Typography>
                                            <Typography variant="h4">
                                                {formatCurrency(costSummary.totalCost)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box textAlign="center">
                                            <Typography variant="body2" color="text.secondary">
                                                Contractor Payments
                                            </Typography>
                                            <Typography variant="h4">
                                                {formatCurrency(costSummary.totalPayment)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Box textAlign="center">
                                            <Typography variant="body2" color="text.secondary">
                                                Profit
                                            </Typography>
                                            <Typography variant="h4" color="success.main">
                                                {formatCurrency(costSummary.profit)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Costs by Category
                            </Typography>

                            <TableContainer component={Paper} variant="outlined" sx={{ height: 300, overflow: 'auto' }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Category</TableCell>
                                            <TableCell align="right">Total Cost</TableCell>
                                            <TableCell align="right">Payments</TableCell>
                                            <TableCell align="right">Profit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(costSummary.byCategory)
                                            .sort(([, a], [, b]) => b.cost - a.cost) // Sort by cost
                                            .map(([category, data]) => {
                                                const profit = data.cost - data.payment;
                                                const percentage = costSummary.totalCost > 0
                                                    ? (data.cost / costSummary.totalCost) * 100
                                                    : 0;

                                                return (
                                                    <TableRow key={category}>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography variant="body2">
                                                                    {getCategoryLabel(category)}
                                                                </Typography>
                                                                <Chip
                                                                    label={`${percentage.toFixed(1)}%`}
                                                                    size="small"
                                                                    sx={{ ml: 1 }}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">{formatCurrency(data.cost)}</TableCell>
                                                        <TableCell align="right">{formatCurrency(data.payment)}</TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="body2" color={profit > 0 ? 'success.main' : 'error.main'}>
                                                                {formatCurrency(profit)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Costs by Work Area
                            </Typography>

                            <TableContainer component={Paper} variant="outlined" sx={{ height: 300, overflow: 'auto' }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Work Area</TableCell>
                                            <TableCell align="right">Total Cost</TableCell>
                                            <TableCell align="right">Profit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(costSummary.byWorkArea)
                                            .sort((a, b) => b.cost - a.cost) // Sort by cost
                                            .map((workArea) => {
                                                const percentage = costSummary.totalCost > 0
                                                    ? (workArea.cost / costSummary.totalCost) * 100
                                                    : 0;

                                                return (
                                                    <TableRow key={workArea.name}>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography variant="body2">
                                                                    {workArea.name}
                                                                </Typography>
                                                                <Chip
                                                                    label={`${percentage.toFixed(1)}%`}
                                                                    size="small"
                                                                    sx={{ ml: 1 }}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="right">{formatCurrency(workArea.cost)}</TableCell>
                                                        <TableCell align="right">
                                                            <Typography variant="body2" color={workArea.profit > 0 ? 'success.main' : 'error.main'}>
                                                                {formatCurrency(workArea.profit)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expandedSection === 'data'}
                onChange={handleSectionExpand('data')}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <CompressIcon sx={{ mr: 1 }} /> Optimized Data Structure
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        <Typography variant="body2">
                            <strong>Optimization Process:</strong> The system will create a lightweight representation
                            of your drawing that only includes the necessary elements. This improves performance
                            significantly compared to working with the full DXF file.
                        </Typography>
                    </Alert>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Original vs. Optimized
                            </Typography>

                            <TableContainer component={Paper} variant="outlined">
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Original Entity Count</TableCell>
                                            <TableCell align="right">{dataReductionMetrics.original.toLocaleString()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Filtered Entity Count</TableCell>
                                            <TableCell align="right">{dataReductionMetrics.filtered.toLocaleString()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Reduction Percentage</TableCell>
                                            <TableCell align="right">{dataReductionMetrics.reduction.toFixed(1)}%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Selected Layers</TableCell>
                                            <TableCell align="right">{selectedLayers.length}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Work Areas</TableCell>
                                            <TableCell align="right">{workAreas.length}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">Work Types</TableCell>
                                            <TableCell align="right">{workTypes.length}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box sx={{ mt: 2 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={dataReductionMetrics.reduction}
                                    color="success"
                                    sx={{ height: 10, borderRadius: 1 }}
                                />
                                <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
                                    Data Reduction: {dataReductionMetrics.reduction.toFixed(1)}%
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Lightweight Data Structure */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Lightweight Data Structure
                            </Typography>

                            <Box
                                component={Paper}
                                variant="outlined"
                                sx={{
                                    p: 2,
                                    fontFamily: 'monospace',
                                    fontSize: '0.8rem',
                                    whiteSpace: 'pre-wrap',
                                    overflow: 'auto',
                                    maxHeight: 300
                                }}
                            >
                                {`{
  "metadata": {
    "name": "${drawing?.name || drawing?.filename || 'Untitled Drawing'}",
    "width": 1000,
    "height": 800,
    "selectedLayers": [${selectedLayers.map(l => `"${l}"`).join(', ')}]
  },
  "workAreas": [
    {
      "id": "wa-123",
      "name": "Example Work Area",
      "category": "WALL",
      "element_ids": ["e1", "e2", "..."],
      "real_dimensions": { "length": 24.5, "unit": "m" }
    },
    ...
  ],
  "workTypes": [
    {
      "id": "wt-123",
      "name": "Example Work Type",
      "unit": "m²",
      "costPerUnit": 45.0,
      "paymentPerUnit": 35.0
    },
    ...
  ],
  "elements": [
    {
      "id": "e1",
      "type": "LINE",
      "points": [{"x": 10, "y": 20}, {"x": 30, "y": 40}],
      "workAreaId": "wa-123",
      "metrics": { "length": 28.28 }
    },
    ...
  ]
}`}
                            </Box>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showEntities}
                                        onChange={(e) => setShowEntities(e.target.checked)}
                                        size="small"
                                    />
                                }
                                label="Show Entities in Raw Data"
                                sx={{ mt: 1 }}
                            />
                        </Grid>

                        {showRawData && (
                            <Grid item xs={12}>
                                <Divider sx={{ mb: 2 }}>
                                    <Chip icon={<CodeIcon />} label="Raw Configuration Data" />
                                </Divider>

                                <Paper
                                    variant="outlined"
                                    sx={{
                                        p: 2,
                                        maxHeight: 300,
                                        overflow: 'auto',
                                        position: 'relative'
                                    }}
                                >
                                    <IconButton
                                        size="small"
                                        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
                                        onClick={() => setShowRawData(false)}
                                    >
                                        <Tooltip title="Hide Raw Data">
                                            <SettingsEthernetIcon />
                                        </Tooltip>
                                    </IconButton>

                                    <Typography variant="subtitle2" gutterBottom>
                                        Selected Layers
                                    </Typography>
                                    <pre style={{ fontSize: '0.75rem', margin: 0, marginBottom: '1rem' }}>
                                        {JSON.stringify(selectedLayers, null, 2)}
                                    </pre>

                                    <Typography variant="subtitle2" gutterBottom>
                                        Work Areas
                                    </Typography>
                                    <pre style={{ fontSize: '0.75rem', margin: 0, marginBottom: '1rem' }}>
                                        {JSON.stringify(workAreas.map(area => ({
                                            id: area.id,
                                            name: area.name,
                                            category: area.category,
                                            element_count: area.element_ids?.length || 0,
                                            ...(showEntities ? { element_ids: area.element_ids } : {}),
                                            real_dimensions: area.real_dimensions,
                                            work_types: area.work_types
                                        })), null, 2)}
                                    </pre>

                                    <Typography variant="subtitle2" gutterBottom>
                                        Work Types
                                    </Typography>
                                    <pre style={{ fontSize: '0.75rem', margin: 0 }}>
                                        {JSON.stringify(workTypes, null, 2)}
                                    </pre>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

ConfigurationSummary.propTypes = {
    drawing: PropTypes.object.isRequired,
    selectedLayers: PropTypes.array.isRequired,
    workAreas: PropTypes.array.isRequired,
    workTypes: PropTypes.array.isRequired,
};

export default ConfigurationSummary;
