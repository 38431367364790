import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Card, 
  CardContent, 
  CardActions, 
  Button, 
  IconButton, 
  Tooltip, 
  Divider, 
  CircularProgress, 
  TextField, 
  InputAdornment,
  Chip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Download as DownloadIcon, 
  Visibility as ViewIcon, 
  Search as SearchIcon,
  SortByAlpha as SortIcon,
  FilterList as FilterIcon,
  MoreVert as MoreIcon,
  Upload as UploadIcon
} from '@mui/icons-material';
import { useAppContext } from '../context/AppContext';
import { format } from 'date-fns';

const DrawingsList = () => {
  const { drawings, loading, error, loadDrawings } = useAppContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('newest');
  const [filteredDrawings, setFilteredDrawings] = useState([]);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [activeMenuDrawingId, setActiveMenuDrawingId] = useState(null);
  
  // Filter and sort drawings when dependencies change
  useEffect(() => {
    if (!drawings) return;
    
    // Filter by search term
    let filtered = drawings.filter(drawing => {
      const searchLower = searchTerm.toLowerCase();
      return (
        (drawing.filename && drawing.filename.toLowerCase().includes(searchLower)) ||
        (drawing.project && drawing.project.toLowerCase().includes(searchLower)) ||
        (drawing.description && drawing.description.toLowerCase().includes(searchLower))
      );
    });
    
    // Sort based on selected option
    filtered = [...filtered].sort((a, b) => {
      switch (sortOption) {
        case 'newest':
          return new Date(b.upload_date) - new Date(a.upload_date);
        case 'oldest':
          return new Date(a.upload_date) - new Date(b.upload_date);
        case 'name_asc':
          return a.filename.localeCompare(b.filename);
        case 'name_desc':
          return b.filename.localeCompare(a.filename);
        default:
          return 0;
      }
    });
    
    setFilteredDrawings(filtered);
  }, [drawings, searchTerm, sortOption]);

  // Menu handling
  const handleMenuOpen = (event, drawingId) => {
    setMenuAnchorEl(event.currentTarget);
    setActiveMenuDrawingId(drawingId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveMenuDrawingId(null);
  };

  // Delete dialog handling
  const openDeleteDialog = (drawing) => {
    setSelectedDrawing(drawing);
    setDeleteDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    // In real application, implement the delete API call here
    // For now, we'll just close the dialog
    setDeleteDialogOpen(false);
    setSelectedDrawing(null);
    
    // Mock successful deletion
    // In production, you'd call an API then reload the list
    setTimeout(() => {
      loadDrawings();
    }, 500);
  };

  // Format date for display
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy');
    } catch (e) {
      return 'Unknown date';
    }
  };

  // Get progress status color
  const getProgressColor = (drawing) => {
    // This would normally come from the drawing's metrics
    // For now we'll just return a random value
    const progress = drawing.progress || Math.floor(Math.random() * 100);
    
    if (progress < 30) return '#f44336'; // Red
    if (progress < 70) return '#ff9800'; // Orange
    return '#4caf50'; // Green
  };

  // Calculate progress percentage
  const getProgressPercentage = (drawing) => {
    // This would normally come from the drawing's metrics
    // For now we'll just return a random value
    return drawing.progress || Math.floor(Math.random() * 100);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Header and controls */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          My Drawings
        </Typography>
        
        <Grid container spacing={2} alignItems="center">
          {/* Search field */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search drawings..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          
          {/* Sort options */}
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SortIcon sx={{ mr: 1, color: 'action.active' }} />
              <TextField
                select
                fullWidth
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                variant="outlined"
                label="Sort by"
              >
                <MenuItem value="newest">Newest First</MenuItem>
                <MenuItem value="oldest">Oldest First</MenuItem>
                <MenuItem value="name_asc">Name (A-Z)</MenuItem>
                <MenuItem value="name_desc">Name (Z-A)</MenuItem>
              </TextField>
            </Box>
          </Grid>
          
          {/* Upload button */}
          <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/upload"
              startIcon={<UploadIcon />}
            >
              Upload New
            </Button>
          </Grid>
        </Grid>
      </Box>
      
      {/* Loading state */}
      {loading.drawings && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}
      
      {/* Error state */}
      {!loading.drawings && error && (
        <Paper sx={{ p: 3, mb: 3, bgcolor: '#ffebee' }}>
          <Typography color="error">{error}</Typography>
          <Button 
            sx={{ mt: 2 }}
            variant="outlined" 
            color="error" 
            onClick={loadDrawings}
          >
            Retry
          </Button>
        </Paper>
      )}
      
      {/* Empty state */}
      {!loading.drawings && !error && filteredDrawings.length === 0 && (
        <Paper sx={{ p: 6, textAlign: 'center' }}>
          {searchTerm ? (
            <>
              <Typography variant="h6" gutterBottom>
                No drawings found matching "{searchTerm}"
              </Typography>
              <Button 
                sx={{ mt: 2 }}
                variant="outlined" 
                onClick={() => setSearchTerm('')}
              >
                Clear Search
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                You haven't uploaded any drawings yet
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Start by uploading a DXF file to visualize and manage it
              </Typography>
              <Button 
                variant="contained" 
                color="primary"
                component={RouterLink}
                to="/upload"
                startIcon={<UploadIcon />}
              >
                Upload DXF File
              </Button>
            </>
          )}
        </Paper>
      )}
      
      {/* Drawing cards */}
      {!loading.drawings && !error && filteredDrawings.length > 0 && (
        <Grid container spacing={3}>
          {filteredDrawings.map((drawing) => (
            <Grid item xs={12} sm={6} md={4} key={drawing.file_id}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4
                }
              }}>
                {/* Progress indicator */}
                <Box sx={{ position: 'relative', height: 8, width: '100%' }}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: '100%',
                      width: `${getProgressPercentage(drawing)}%`,
                      bgcolor: getProgressColor(drawing),
                    }}
                  />
                </Box>
                
                {/* Card content */}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography variant="h6" component="div" noWrap title={drawing.filename}>
                      {drawing.name || drawing.filename}
                    </Typography>
                    <IconButton 
                      size="small" 
                      onClick={(e) => handleMenuOpen(e, drawing.file_id)}
                      aria-label="more options"
                    >
                      <MoreIcon />
                    </IconButton>
                  </Box>
                  
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Uploaded: {formatDate(drawing.upload_date)}
                  </Typography>
                  
                  {drawing.project && (
                    <Chip 
                      label={drawing.project} 
                      size="small" 
                      sx={{ mr: 1, mb: 1 }} 
                    />
                  )}
                  
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mt: 1,
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      height: '40px'
                    }}
                  >
                    {drawing.description || 'No description provided'}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Typography variant="body2">
                      Progress: {getProgressPercentage(drawing)}%
                    </Typography>
                  </Box>
                </CardContent>
                
                <Divider />
                
                {/* Card actions */}
                <CardActions>
                  <Button 
                    size="small" 
                    startIcon={<ViewIcon />}
                    component={RouterLink}
                    to={`/viewer/${drawing.file_id}`}
                  >
                    View
                  </Button>
                  <Button 
                    size="small" 
                    startIcon={<DownloadIcon />}
                  >
                    Download
                  </Button>
                  <Button 
                    size="small" 
                    color="error" 
                    onClick={() => openDeleteDialog(drawing)}
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      
      {/* More options menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem 
          component={RouterLink} 
          to={`/viewer/${activeMenuDrawingId}`}
          onClick={handleMenuClose}
        >
          <ViewIcon fontSize="small" sx={{ mr: 1 }} />
          View
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Details
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
          Download
        </MenuItem>
        <Divider />
        <MenuItem 
          onClick={() => {
            const drawing = drawings.find(d => d.file_id === activeMenuDrawingId);
            if (drawing) openDeleteDialog(drawing);
          }} 
          sx={{ color: 'error.main' }}
        >
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      
      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Drawing</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{selectedDrawing?.name || selectedDrawing?.filename}"? 
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DrawingsList;
