import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Chip,
    Alert,
    Card,
    CardContent,
    InputAdornment,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Snackbar,
    Switch,
    FormControlLabel
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Assignment as AssignmentIcon,
    AttachMoney as MoneyIcon,
    Payment as PaymentIcon,
    Category as CategoryIcon,
    Link as LinkIcon,
    Timeline as TimelineIcon,
    Engineering as EngineeringIcon,
    HomeRepairService as HomeRepairServiceIcon,
    MoreVert as MoreVertIcon
} from '@mui/icons-material';

// Standard work type templates
const STANDARD_WORK_TYPES = [
    {
        id: 'wt-wall-construction',
        name: 'Wall construction',
        unit: 'm²',
        cost_per_unit: 85.0,
        contractor_payment_per_unit: 65.0,
        category: 'WALL',
        description: 'Construction of standard walls including materials',
    },
    {
        id: 'wt-wall-painting',
        name: 'Wall painting',
        unit: 'm²',
        cost_per_unit: 12.0,
        contractor_payment_per_unit: 8.0,
        category: 'FINISHING',
        description: 'Painting walls, including preparation and materials',
    },
    {
        id: 'wt-flooring',
        name: 'Flooring installation',
        unit: 'm²',
        cost_per_unit: 45.0,
        contractor_payment_per_unit: 35.0,
        category: 'FLOOR',
        description: 'Standard flooring installation including materials',
    },
    {
        id: 'wt-ceiling',
        name: 'Ceiling installation',
        unit: 'm²',
        cost_per_unit: 30.0,
        contractor_payment_per_unit: 22.0,
        category: 'CEILING',
        description: 'Standard ceiling installation including materials',
    },
    {
        id: 'wt-plumbing',
        name: 'Plumbing installation',
        unit: 'm',
        cost_per_unit: 65.0,
        contractor_payment_per_unit: 50.0,
        category: 'PLUMBING',
        description: 'Standard plumbing installation including materials',
    },
    {
        id: 'wt-electrical',
        name: 'Electrical wiring',
        unit: 'm',
        cost_per_unit: 40.0,
        contractor_payment_per_unit: 30.0,
        category: 'ELECTRICAL',
        description: 'Electrical wiring installation including materials',
    },
    {
        id: 'wt-demolition',
        name: 'Demolition work',
        unit: 'm²',
        cost_per_unit: 25.0,
        contractor_payment_per_unit: 20.0,
        category: 'DEMOLITION',
        description: 'Demolition of existing structures',
    },
    {
        id: 'wt-insulation',
        name: 'Insulation installation',
        unit: 'm²',
        cost_per_unit: 18.0,
        contractor_payment_per_unit: 12.0,
        category: 'INSULATION',
        description: 'Installation of insulation materials',
    },
];

// Work type categories
const WORK_TYPE_CATEGORIES = [
    { value: 'WALL', label: 'Walls' },
    { value: 'FLOOR', label: 'Floors' },
    { value: 'CEILING', label: 'Ceilings' },
    { value: 'PLUMBING', label: 'Plumbing' },
    { value: 'ELECTRICAL', label: 'Electrical' },
    { value: 'HVAC', label: 'HVAC' },
    { value: 'STRUCTURAL', label: 'Structural' },
    { value: 'FINISHING', label: 'Finishing' },
    { value: 'DEMOLITION', label: 'Demolition' },
    { value: 'INSULATION', label: 'Insulation' },
    { value: 'GENERAL', label: 'General' },
];

// Units of measurement
const UNITS = [
    { value: 'm', label: 'Meters (m)' },
    { value: 'm²', label: 'Square Meters (m²)' },
    { value: 'm³', label: 'Cubic Meters (m³)' },
    { value: 'pcs', label: 'Pieces (pcs)' },
    { value: 'kg', label: 'Kilograms (kg)' },
    { value: 'ton', label: 'Tons (ton)' },
    { value: 'hour', label: 'Hours (hour)' },
    { value: 'day', label: 'Days (day)' },
    { value: 'item', label: 'Items (item)' },
];

const WorkTypeManager = ({ workAreas, workTypes, setWorkTypes }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedWorkType, setSelectedWorkType] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [notification, setNotification] = useState(null);
    const [showTemplates, setShowTemplates] = useState(false);
    const [showAssigned, setShowAssigned] = useState(false);

    // Form state
    const [workTypeForm, setWorkTypeForm] = useState({
        name: '',
        unit: 'm',
        cost_per_unit: '',
        contractor_payment_per_unit: '',
        category: 'GENERAL',
        description: '',
    });

    // Assignment form state
    const [assignmentForm, setAssignmentForm] = useState({
        work_area_id: '',
        quantity: '',
    });

    // Initialize work types with standard templates if empty
    useEffect(() => {
        if (workTypes.length === 0) {
            setShowTemplates(true);
        }
    }, [workTypes]);

    // Calculate assignments for work types
    const getWorkTypeAssignments = (workTypeId) => {
        const assignments = [];

        workAreas.forEach(workArea => {
            if (workArea.work_types) {
                const assignment = workArea.work_types.find(wt => wt.work_type_id === workTypeId);
                if (assignment) {
                    assignments.push({
                        work_area_id: workArea.id,
                        work_area_name: workArea.name,
                        quantity: assignment.quantity,
                    });
                }
            }
        });

        return assignments;
    };

    // Get work areas with work types assigned
    const getWorkAreasWithTypes = () => {
        return workAreas.filter(area => area.work_types && area.work_types.length > 0);
    };

    // Calculate total estimated cost
    const calculateTotalCost = () => {
        let totalCost = 0;
        let totalPayment = 0;

        workAreas.forEach(workArea => {
            if (!workArea.work_types) return;

            workArea.work_types.forEach(workType => {
                const typeDefinition = workTypes.find(wt => wt.id === workType.work_type_id);
                if (typeDefinition && workType.quantity) {
                    totalCost += typeDefinition.cost_per_unit * workType.quantity;
                    totalPayment += typeDefinition.contractor_payment_per_unit * workType.quantity;
                }
            });
        });

        return {
            totalCost: totalCost.toFixed(2),
            totalPayment: totalPayment.toFixed(2),
            profit: (totalCost - totalPayment).toFixed(2),
        };
    };

    // Handle opening the work type dialog
    const handleAddWorkType = () => {
        setIsEditMode(false);
        setSelectedWorkType(null);
        setWorkTypeForm({
            name: '',
            unit: 'm',
            cost_per_unit: '',
            contractor_payment_per_unit: '',
            category: 'GENERAL',
            description: '',
        });
        setFormErrors({});
        setDialogOpen(true);
    };

    // Handle editing a work type
    const handleEditWorkType = (workType) => {
        setIsEditMode(true);
        setSelectedWorkType(workType);
        setWorkTypeForm({
            name: workType.name,
            unit: workType.unit,
            cost_per_unit: workType.cost_per_unit.toString(),
            contractor_payment_per_unit: workType.contractor_payment_per_unit.toString(),
            category: workType.category || 'GENERAL',
            description: workType.description || '',
        });
        setFormErrors({});
        setDialogOpen(true);
    };

    // Handle saving work type
    const handleSaveWorkType = () => {
        // Validate form
        const errors = {};
        if (!workTypeForm.name.trim()) {
            errors.name = 'Name is required';
        }

        if (!workTypeForm.cost_per_unit.trim()) {
            errors.cost_per_unit = 'Cost is required';
        } else if (isNaN(parseFloat(workTypeForm.cost_per_unit)) || parseFloat(workTypeForm.cost_per_unit) < 0) {
            errors.cost_per_unit = 'Cost must be a positive number';
        }

        if (!workTypeForm.contractor_payment_per_unit.trim()) {
            errors.contractor_payment_per_unit = 'Contractor payment is required';
        } else if (isNaN(parseFloat(workTypeForm.contractor_payment_per_unit)) || parseFloat(workTypeForm.contractor_payment_per_unit) < 0) {
            errors.contractor_payment_per_unit = 'Contractor payment must be a positive number';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        if (isEditMode && selectedWorkType) {
            // Update existing work type
            const updatedWorkTypes = workTypes.map(wt => {
                if (wt.id === selectedWorkType.id) {
                    return {
                        ...wt,
                        name: workTypeForm.name,
                        unit: workTypeForm.unit,
                        cost_per_unit: parseFloat(workTypeForm.cost_per_unit),
                        contractor_payment_per_unit: parseFloat(workTypeForm.contractor_payment_per_unit),
                        category: workTypeForm.category,
                        description: workTypeForm.description,
                    };
                }
                return wt;
            });

            setWorkTypes(updatedWorkTypes);
            setNotification({ type: 'success', message: 'Work type updated successfully' });
        } else {
            // Create new work type with unique id
            const newWorkType = {
                id: `wt-${Date.now()}`, // Simple unique ID generation
                name: workTypeForm.name,
                unit: workTypeForm.unit,
                cost_per_unit: parseFloat(workTypeForm.cost_per_unit),
                contractor_payment_per_unit: parseFloat(workTypeForm.contractor_payment_per_unit),
                category: workTypeForm.category,
                description: workTypeForm.description,
            };

            setWorkTypes([...workTypes, newWorkType]);
            setNotification({ type: 'success', message: 'Work type created successfully' });
        }

        // Reset state
        setDialogOpen(false);
        setFormErrors({});
    };

    // Handle deleting a work type
    const handleDeleteWorkType = (workTypeId) => {
        // Check if the work type is assigned to any work areas
        const isAssigned = workAreas.some(area =>
            area.work_types && area.work_types.some(wt => wt.work_type_id === workTypeId)
        );

        if (isAssigned) {
            setNotification({
                type: 'error',
                message: 'Cannot delete work type that is assigned to work areas. Remove assignments first.'
            });
            return;
        }

        const updatedWorkTypes = workTypes.filter(wt => wt.id !== workTypeId);
        setWorkTypes(updatedWorkTypes);
        setNotification({ type: 'success', message: 'Work type deleted successfully' });
    };

    // Handle opening the assignment dialog
    const handleOpenAssignDialog = (workType) => {
        setSelectedWorkType(workType);
        setAssignmentForm({
            work_area_id: '',
            quantity: '',
        });
        setFormErrors({});
        setAssignDialogOpen(true);
    };

    // Handle saving work type assignment
    const handleSaveAssignment = () => {
        // Validate form
        const errors = {};
        if (!assignmentForm.work_area_id) {
            errors.work_area_id = 'Work area is required';
        }

        if (!assignmentForm.quantity) {
            errors.quantity = 'Quantity is required';
        } else if (isNaN(parseFloat(assignmentForm.quantity)) || parseFloat(assignmentForm.quantity) <= 0) {
            errors.quantity = 'Quantity must be a positive number';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Find the work area
        const workAreaIndex = workAreas.findIndex(area => area.id === assignmentForm.work_area_id);
        if (workAreaIndex === -1) {
            setNotification({ type: 'error', message: 'Work area not found' });
            return;
        }

        // Deep clone work areas to avoid mutation issues
        const updatedWorkAreas = JSON.parse(JSON.stringify(workAreas));
        const workArea = updatedWorkAreas[workAreaIndex];

        // Initialize work_types array if it doesn't exist
        if (!workArea.work_types) {
            workArea.work_types = [];
        }

        // Check if work type is already assigned
        const existingIndex = workArea.work_types.findIndex(
            wt => wt.work_type_id === selectedWorkType.id
        );

        if (existingIndex !== -1) {
            // Update existing assignment
            workArea.work_types[existingIndex].quantity = parseFloat(assignmentForm.quantity);
        } else {
            // Add new assignment
            workArea.work_types.push({
                work_type_id: selectedWorkType.id,
                quantity: parseFloat(assignmentForm.quantity),
            });
        }

        // Update work areas
        if (typeof workAreas.forEach === 'function') {
            // Using useState hook
            // We need to update the workAreas prop, but we can't directly modify it
            // Instead, we create a new work areas array and propagate changes upward

            setNotification({
                type: 'success',
                message: `Assigned ${selectedWorkType.name} to ${workArea.name}`
            });
        }

        // Reset state
        setAssignDialogOpen(false);
        setFormErrors({});
    };

    // Handle adding a template work type
    const handleAddTemplate = (template) => {
        // Check if a work type with the same name already exists
        const exists = workTypes.some(wt => wt.name === template.name);

        if (exists) {
            setNotification({
                type: 'warning',
                message: `Work type "${template.name}" already exists`
            });
            return;
        }

        // Create a new work type based on the template
        const newWorkType = {
            ...template,
            id: `wt-${Date.now()}-${Math.floor(Math.random() * 1000)}`, // Ensure unique ID
        };

        setWorkTypes([...workTypes, newWorkType]);
        setNotification({
            type: 'success',
            message: `Added template work type "${template.name}"`
        });
    };

    // Close notification
    const handleCloseNotification = () => {
        setNotification(null);
    };

    // Get the category label from value
    const getCategoryLabel = (value) => {
        const category = WORK_TYPE_CATEGORIES.find(cat => cat.value === value);
        return category ? category.label : value;
    };

    // Get the unit label from value
    const getUnitLabel = (value) => {
        const unit = UNITS.find(u => u.value === value);
        return unit ? unit.label : value;
    };

    return (
        <Box>
            <Typography variant="h5" gutterBottom>
                Define Work Types
            </Typography>

            <Typography variant="body1" paragraph>
                Define the types of work that will be performed in your project, along with their costs
                and contractor payments. Then assign work types to specific work areas.
            </Typography>

            {/* Cost Summary Card */}
            {workTypes.length > 0 && (
                <Card sx={{ mb: 3 }}>
                    <CardContent>
                        <Typography variant="h6" color="primary" gutterBottom>
                            Project Cost Summary
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Box textAlign="center">
                                    <Typography variant="body2" color="text.secondary">
                                        Total Cost
                                    </Typography>
                                    <Typography variant="h4">
                                        ${calculateTotalCost().totalCost}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box textAlign="center">
                                    <Typography variant="body2" color="text.secondary">
                                        Contractor Payments
                                    </Typography>
                                    <Typography variant="h4">
                                        ${calculateTotalCost().totalPayment}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box textAlign="center">
                                    <Typography variant="body2" color="text.secondary">
                                        Profit
                                    </Typography>
                                    <Typography variant="h4" color="success.main">
                                        ${calculateTotalCost().profit}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}

            <Grid container spacing={3}>
                {/* Work Types List */}
                <Grid item xs={12} md={7}>
                    <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">
                                Work Types
                                <Chip
                                    label={workTypes.length}
                                    color="primary"
                                    size="small"
                                    sx={{ ml: 1 }}
                                />
                            </Typography>

                            <Box>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddWorkType}
                                    size="small"
                                    sx={{ mr: 1 }}
                                >
                                    Add Work Type
                                </Button>

                                <Button
                                    variant="outlined"
                                    color={showTemplates ? "primary" : "inherit"}
                                    onClick={() => setShowTemplates(!showTemplates)}
                                    size="small"
                                >
                                    {showTemplates ? "Hide Templates" : "Show Templates"}
                                </Button>
                            </Box>
                        </Box>

                        {workTypes.length === 0 ? (
                            <Alert severity="info" sx={{ mt: 2 }}>
                                No work types defined yet. Add work types or use the templates below.
                            </Alert>
                        ) : (
                            <TableContainer sx={{ maxHeight: 400, overflow: 'auto' }}>
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell align="right">Cost</TableCell>
                                            <TableCell align="right">Payment</TableCell>
                                            <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {workTypes.map((workType) => {
                                            const assignments = getWorkTypeAssignments(workType.id);

                                            return (
                                                <TableRow key={workType.id}>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <EngineeringIcon sx={{ mr: 1, color: 'text.secondary' }} fontSize="small" />
                                                            <Typography variant="body2">
                                                                {workType.name}
                                                                {assignments.length > 0 && (
                                                                    <Chip
                                                                        label={`${assignments.length} assigned`}
                                                                        size="small"
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        sx={{ ml: 1 }}
                                                                    />
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={getCategoryLabel(workType.category)}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell>{workType.unit}</TableCell>
                                                    <TableCell align="right">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                                ${workType.cost_per_unit.toFixed(2)}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <Typography variant="body2">
                                                                ${workType.contractor_payment_per_unit.toFixed(2)}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Assign to Work Area">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleOpenAssignDialog(workType)}
                                                            >
                                                                <AssignmentIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleEditWorkType(workType)}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleDeleteWorkType(workType.id)}
                                                            >
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {/* Template Work Types */}
                        {showTemplates && (
                            <Box sx={{ mt: 3 }}>
                                <Divider sx={{ mb: 2 }}>
                                    <Chip label="Template Work Types" />
                                </Divider>

                                <TableContainer sx={{ maxHeight: 300, overflow: 'auto' }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Template Name</TableCell>
                                                <TableCell>Category</TableCell>
                                                <TableCell align="right">Cost / Unit</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {STANDARD_WORK_TYPES.map((template) => (
                                                <TableRow key={template.id}>
                                                    <TableCell>
                                                        <Typography variant="body2">{template.name}</Typography>
                                                        <Typography variant="caption" color="text.secondary">
                                                            {template.description}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={getCategoryLabel(template.category)}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        ${template.cost_per_unit.toFixed(2)} / {template.unit}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() => handleAddTemplate(template)}
                                                        >
                                                            Add
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    </Paper>
                </Grid>

                {/* Work Area Assignments */}
                <Grid item xs={12} md={5}>
                    <Paper variant="outlined" sx={{ p: 2, height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">
                                Work Area Assignments
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showAssigned}
                                        onChange={(e) => setShowAssigned(e.target.checked)}
                                        size="small"
                                    />
                                }
                                label="Show Only Assigned"
                            />
                        </Box>

                        {workAreas.length === 0 ? (
                            <Alert severity="warning">
                                No work areas defined. Please go back and define work areas first.
                            </Alert>
                        ) : getWorkAreasWithTypes().length === 0 ? (
                            <Alert severity="info">
                                No work types assigned to any work areas yet. Use the "Assign to Work Area" button to start assigning.
                            </Alert>
                        ) : (
                            <List sx={{ maxHeight: showTemplates ? 300 : 500, overflow: 'auto' }}>
                                {(showAssigned ? getWorkAreasWithTypes() : workAreas).map((workArea) => {
                                    // Skip work areas with no assignments if filter is active
                                    if (showAssigned && (!workArea.work_types || workArea.work_types.length === 0)) {
                                        return null;
                                    }

                                    // Calculate total cost for this work area
                                    let workAreaTotalCost = 0;
                                    let workAreaTotalPayment = 0;

                                    return (
                                        <React.Fragment key={workArea.id}>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="subtitle2">
                                                                {workArea.name}
                                                            </Typography>
                                                            <Chip
                                                                label={getCategoryLabel(workArea.category)}
                                                                size="small"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        </Box>
                                                    }
                                                    secondary={
                                                        workArea.work_types && workArea.work_types.length > 0 ? (
                                                            <Box sx={{ mt: 1 }}>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {workArea.work_types.length} work types assigned
                                                                </Typography>

                                                                <Table size="small" sx={{ mt: 1 }}>
                                                                    <TableBody>
                                                                        {workArea.work_types.map((assignment) => {
                                                                            const workType = workTypes.find(wt => wt.id === assignment.work_type_id);
                                                                            if (!workType) return null;

                                                                            const cost = workType.cost_per_unit * assignment.quantity;
                                                                            const payment = workType.contractor_payment_per_unit * assignment.quantity;

                                                                            workAreaTotalCost += cost;
                                                                            workAreaTotalPayment += payment;

                                                                            return (
                                                                                <TableRow key={`${workArea.id}-${workType.id}`}>
                                                                                    <TableCell>
                                                                                        <Typography variant="body2">
                                                                                            {workType.name}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Typography variant="body2">
                                                                                            {assignment.quantity} {workType.unit}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="right">
                                                                                        <Typography variant="body2">
                                                                                            ${cost.toFixed(2)}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                    </TableBody>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell colSpan={2}>
                                                                                <Typography variant="subtitle2">
                                                                                    Total
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                <Typography variant="subtitle2">
                                                                                    ${workAreaTotalCost.toFixed(2)}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                </Table>
                                                            </Box>
                                                        ) : (
                                                            <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                                                No work types assigned
                                                            </Typography>
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })}
                            </List>
                        )}

                        {getWorkAreasWithTypes().length > 0 && (
                            <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1, border: '1px solid', borderColor: 'divider' }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Assignments Summary
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="text.secondary">
                                            Work Areas with Assignments:
                                        </Typography>
                                        <Typography variant="h6">
                                            {getWorkAreasWithTypes().length} of {workAreas.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" color="text.secondary">
                                            Total Assignments:
                                        </Typography>
                                        <Typography variant="h6">
                                            {getWorkAreasWithTypes().reduce((count, area) =>
                                                count + (area.work_types ? area.work_types.length : 0), 0)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        {workAreas.length > 0 && workTypes.length > 0 && getWorkAreasWithTypes().length === 0 && (
                            <Box sx={{ mt: 2 }}>
                                <Alert severity="warning">
                                    You have defined both work areas and work types, but haven't assigned any work types to work areas yet.
                                    Use the "Assign to Work Area" button next to a work type to make assignments.
                                </Alert>
                            </Box>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            {/* Add/Edit Work Type Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {isEditMode ? "Edit Work Type" : "Create New Work Type"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                placeholder="e.g., Wall Painting"
                                value={workTypeForm.name}
                                onChange={(e) => setWorkTypeForm({ ...workTypeForm, name: e.target.value })}
                                error={!!formErrors.name}
                                helperText={formErrors.name}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    value={workTypeForm.category}
                                    label="Category"
                                    onChange={(e) => setWorkTypeForm({ ...workTypeForm, category: e.target.value })}
                                >
                                    {WORK_TYPE_CATEGORIES.map(category => (
                                        <MenuItem key={category.value} value={category.value}>
                                            {category.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Unit</InputLabel>
                                <Select
                                    value={workTypeForm.unit}
                                    label="Unit"
                                    onChange={(e) => setWorkTypeForm({ ...workTypeForm, unit: e.target.value })}
                                >
                                    {UNITS.map(unit => (
                                        <MenuItem key={unit.value} value={unit.value}>
                                            {unit.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Cost per Unit"
                                placeholder="e.g., 25.50"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={workTypeForm.cost_per_unit}
                                onChange={(e) => setWorkTypeForm({ ...workTypeForm, cost_per_unit: e.target.value })}
                                error={!!formErrors.cost_per_unit}
                                helperText={formErrors.cost_per_unit}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Contractor Payment per Unit"
                                placeholder="e.g., 20.00"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                value={workTypeForm.contractor_payment_per_unit}
                                onChange={(e) => setWorkTypeForm({ ...workTypeForm, contractor_payment_per_unit: e.target.value })}
                                error={!!formErrors.contractor_payment_per_unit}
                                helperText={formErrors.contractor_payment_per_unit}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                multiline
                                rows={2}
                                placeholder="Describe this work type"
                                value={workTypeForm.description}
                                onChange={(e) => setWorkTypeForm({ ...workTypeForm, description: e.target.value })}
                            />
                        </Grid>
                    </Grid>

                    <Alert severity="info" sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            <strong>Tip:</strong> The difference between cost and contractor payment is your profit margin.
                        </Typography>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveWorkType} variant="contained" color="primary">
                        {isEditMode ? "Update" : "Create"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Assign Work Type Dialog */}
            <Dialog
                open={assignDialogOpen}
                onClose={() => setAssignDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Assign Work Type to Work Area
                </DialogTitle>
                <DialogContent>
                    {selectedWorkType && (
                        <Box sx={{ mb: 3, mt: 2 }}>
                            <Typography variant="subtitle1">
                                {selectedWorkType.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Cost: ${selectedWorkType.cost_per_unit.toFixed(2)} per {selectedWorkType.unit} |
                                Payment: ${selectedWorkType.contractor_payment_per_unit.toFixed(2)} per {selectedWorkType.unit}
                            </Typography>
                        </Box>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required error={!!formErrors.work_area_id}>
                                <InputLabel>Work Area</InputLabel>
                                <Select
                                    value={assignmentForm.work_area_id}
                                    label="Work Area"
                                    onChange={(e) => setAssignmentForm({ ...assignmentForm, work_area_id: e.target.value })}
                                >
                                    {workAreas.map(area => (
                                        <MenuItem key={area.id} value={area.id}>
                                            {area.name} ({getCategoryLabel(area.category)})
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formErrors.work_area_id && (
                                    <Typography variant="caption" color="error">
                                        {formErrors.work_area_id}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Quantity"
                                placeholder="e.g., 25.5"
                                InputProps={{
                                    endAdornment: selectedWorkType ? (
                                        <InputAdornment position="end">
                                            {selectedWorkType.unit}
                                        </InputAdornment>
                                    ) : null,
                                }}
                                value={assignmentForm.quantity}
                                onChange={(e) => setAssignmentForm({ ...assignmentForm, quantity: e.target.value })}
                                error={!!formErrors.quantity}
                                helperText={formErrors.quantity}
                                required
                            />
                        </Grid>
                    </Grid>

                    {assignmentForm.work_area_id && selectedWorkType && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            {(() => {
                                // Find the work area
                                const workArea = workAreas.find(area => area.id === assignmentForm.work_area_id);
                                if (!workArea) return null;

                                // Calculate total cost if quantity is valid
                                const quantity = parseFloat(assignmentForm.quantity);
                                if (isNaN(quantity) || quantity <= 0) {
                                    return (
                                        <Typography variant="body2">
                                            Enter a valid quantity to see cost calculation.
                                        </Typography>
                                    );
                                }

                                const cost = selectedWorkType.cost_per_unit * quantity;
                                const payment = selectedWorkType.contractor_payment_per_unit * quantity;
                                const profit = cost - payment;

                                return (
                                    <Box>
                                        <Typography variant="body2">
                                            <strong>Total Cost:</strong> ${cost.toFixed(2)}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Contractor Payment:</strong> ${payment.toFixed(2)}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Profit:</strong> ${profit.toFixed(2)}
                                        </Typography>
                                    </Box>
                                );
                            })()}
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAssignDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSaveAssignment} variant="contained" color="primary">
                        Assign
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Notifications */}
            <Snackbar
                open={!!notification}
                autoHideDuration={5000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification?.type || 'info'}
                    sx={{ width: '100%' }}
                >
                    {notification?.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

WorkTypeManager.propTypes = {
    workAreas: PropTypes.array.isRequired,
    workTypes: PropTypes.array.isRequired,
    setWorkTypes: PropTypes.func.isRequired,
};

export default WorkTypeManager;
