import api from './api';

/**
 * Configure drawing layers
 * @param {string} fileId - The ID of the drawing
 * @param {Object} layerConfig - Configuration object with selected_layers array
 * @returns {Promise<Object>} - Response data
 */
export const configureDrawingLayers = async (fileId, layerConfig) => {
    try {
        const response = await api.post(`/drawings/${fileId}/configure/layers`, layerConfig);
        return response.data;
    } catch (error) {
        console.error(`Error configuring layers for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Configure drawing work areas
 * @param {string} fileId - The ID of the drawing
 * @param {Array} workAreas - Array of work area definitions
 * @returns {Promise<Object>} - Response data
 */
export const configureDrawingWorkAreas = async (fileId, workAreas) => {
    try {
        const response = await api.post(`/drawings/${fileId}/configure/workareas`, workAreas);
        return response.data;
    } catch (error) {
        console.error(`Error configuring work areas for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Configure drawing work types
 * @param {string} fileId - The ID of the drawing
 * @param {Array} workTypes - Array of work type definitions
 * @returns {Promise<Object>} - Response data
 */
export const configureDrawingWorkTypes = async (fileId, workTypes) => {
    try {
        const response = await api.post(`/drawings/${fileId}/configure/worktypes`, workTypes);
        return response.data;
    } catch (error) {
        console.error(`Error configuring work types for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Finalize drawing configuration
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Response data with metrics about the conversion
 */
export const finalizeDrawingConfiguration = async (fileId) => {
    try {
        const response = await api.post(`/drawings/${fileId}/finalize`);
        return response.data;
    } catch (error) {
        console.error(`Error finalizing configuration for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Create a new work area
 * @param {string} fileId - The ID of the drawing
 * @param {Object} workAreaData - Work area data
 * @returns {Promise<Object>} - Created work area
 */
export const createWorkArea = async (fileId, workAreaData) => {
    try {
        const response = await api.post(`/drawings/${fileId}/workareas`, workAreaData);
        return response.data;
    } catch (error) {
        console.error(`Error creating work area for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Update a work area
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @param {Object} workAreaData - Updated work area data
 * @returns {Promise<Object>} - Updated work area
 */
export const updateWorkArea = async (fileId, workAreaId, workAreaData) => {
    try {
        const response = await api.put(`/drawings/${fileId}/workareas/${workAreaId}`, workAreaData);
        return response.data;
    } catch (error) {
        console.error(`Error updating work area ${workAreaId} for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Delete a work area
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @returns {Promise<Object>} - Response data
 */
export const deleteWorkArea = async (fileId, workAreaId) => {
    try {
        const response = await api.delete(`/drawings/${fileId}/workareas/${workAreaId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting work area ${workAreaId} for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Get standard work types
 * @param {string} fileId - The ID of the drawing (used for API path but not needed for functionality)
 * @returns {Promise<Array>} - Array of standard work types
 */
export const getStandardWorkTypes = async (fileId) => {
    try {
        const response = await api.get(`/drawings/${fileId}/standard-worktypes`);
        return response.data;
    } catch (error) {
        console.error(`Error getting standard work types for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Create a new work type
 * @param {string} fileId - The ID of the drawing
 * @param {Object} workTypeData - Work type data
 * @returns {Promise<Object>} - Created work type
 */
export const createWorkType = async (fileId, workTypeData) => {
    try {
        const response = await api.post(`/drawings/${fileId}/worktypes`, workTypeData);
        return response.data;
    } catch (error) {
        console.error(`Error creating work type for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Update a work type
 * @param {string} fileId - The ID of the drawing
 * @param {string} workTypeId - The ID of the work type
 * @param {Object} workTypeData - Updated work type data
 * @returns {Promise<Object>} - Updated work type
 */
export const updateWorkType = async (fileId, workTypeId, workTypeData) => {
    try {
        const response = await api.put(`/drawings/${fileId}/worktypes/${workTypeId}`, workTypeData);
        return response.data;
    } catch (error) {
        console.error(`Error updating work type ${workTypeId} for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Delete a work type
 * @param {string} fileId - The ID of the drawing
 * @param {string} workTypeId - The ID of the work type
 * @returns {Promise<Object>} - Response data
 */
export const deleteWorkType = async (fileId, workTypeId) => {
    try {
        const response = await api.delete(`/drawings/${fileId}/worktypes/${workTypeId}`);
        return response.data;
    } catch (error) {
        console.error(`Error deleting work type ${workTypeId} for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Assign work type to work area
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @param {Object} assignmentData - Work type assignment data
 * @returns {Promise<Object>} - Response data
 */
export const assignWorkType = async (fileId, workAreaId, assignmentData) => {
    try {
        const response = await api.post(`/drawings/${fileId}/workareas/${workAreaId}/worktypes`,
            assignmentData
        );
        return response.data;
    } catch (error) {
        console.error(`Error assigning work type to work area ${workAreaId} for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Remove work type assignment from work area
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @param {string} workTypeId - The ID of the work type
 * @returns {Promise<Object>} - Response data
 */
export const removeWorkTypeAssignment = async (fileId, workAreaId, workTypeId) => {
    try {
        const response = await api.delete(
            `/drawings/${fileId}/workareas/${workAreaId}/worktypes/${workTypeId}`
        );
        return response.data;
    } catch (error) {
        console.error(`Error removing work type ${workTypeId} from work area ${workAreaId}:`, error);
        throw error;
    }
};

/**
 * Update work dimensions
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @param {Object} dimensionsData - Real dimensions data
 * @returns {Promise<Object>} - Response data
 */
export const updateWorkDimensions = async (fileId, workAreaId, dimensionsData) => {
    try {
        const response = await api.patch(
            `/drawings/${fileId}/workareas/${workAreaId}/dimensions`,
            dimensionsData
        );
        return response.data;
    } catch (error) {
        console.error(`Error updating dimensions for work area ${workAreaId}:`, error);
        throw error;
    }
};

/**
 * Get work area information
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @returns {Promise<Object>} - Work area data
 */
export const getWorkArea = async (fileId, workAreaId) => {
    try {
        const response = await api.get(`/drawings/${fileId}/workareas/${workAreaId}`);
        return response.data;
    } catch (error) {
        console.error(`Error getting work area ${workAreaId} for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Get all work areas for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - Array of work areas
 */
export const getWorkAreas = async (fileId) => {
    try {
        const response = await api.get(`/drawings/${fileId}/workareas`);
        return response.data;
    } catch (error) {
        console.error(`Error getting work areas for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Get all work types for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - Array of work types
 */
export const getWorkTypes = async (fileId) => {
    try {
        const response = await api.get(`/drawings/${fileId}/worktypes`);
        return response.data;
    } catch (error) {
        console.error(`Error getting work types for drawing ${fileId}:`, error);
        throw error;
    }
};

/**
 * Update status of an element
 * @param {string} fileId - The ID of the drawing
 * @param {string} elementId - The ID of the element
 * @param {string} status - New status
 * @returns {Promise<Object>} - Response data
 */
export const updateElementStatus = async (fileId, elementId, status) => {
    try {
        const response = await api.patch(
            `/drawings/${fileId}/elements/${elementId}/status`,
            { status }
        );
        return response.data;
    } catch (error) {
        console.error(`Error updating element status for ${elementId}:`, error);
        throw error;
    }
};

/**
 * Update a segment's status
 * @param {string} fileId - The ID of the drawing
 * @param {string} elementId - The ID of the element
 * @param {string} segmentId - The ID of the segment
 * @param {Object} updateData - Update data with status and completion
 * @returns {Promise<Object>} - Response data
 */
export const updateElementSegment = async (fileId, elementId, segmentId, updateData) => {
    try {
        const response = await api.patch(
            `/drawings/${fileId}/elements/${elementId}/segments/${segmentId}`,
            updateData
        );
        return response.data;
    } catch (error) {
        console.error(`Error updating segment ${segmentId} status:`, error);
        throw error;
    }
};

/**
 * Update multiple segments at once
 * @param {string} fileId - The ID of the drawing
 * @param {string} elementId - The ID of the element
 * @param {Array} segments - Array of segment update objects with id, status, and completion
 * @returns {Promise<Object>} - Response data
 */
export const updateMultipleSegments = async (fileId, elementId, segments) => {
    try {
        const response = await api.patch(
            `/drawings/${fileId}/elements/${elementId}/segments`,
            segments
        );
        return response.data;
    } catch (error) {
        console.error(`Error updating multiple segments for element ${elementId}:`, error);
        throw error;
    }
};

/**
 * Get all segments for an element
 * @param {string} fileId - The ID of the drawing
 * @param {string} elementId - The ID of the element
 * @returns {Promise<Object>} - Object with element ID and segments array
 */
export const getElementSegments = async (fileId, elementId) => {
    try {
        const response = await api.get(`/drawings/${fileId}/elements/${elementId}/segments`);
        return response.data;
    } catch (error) {
        console.error(`Error getting segments for element ${elementId}:`, error);
        throw error;
    }
};

/**
 * Get display settings for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Display settings
 */
export const getDisplaySettings = async (fileId) => {
    try {
        const response = await api.get(`/drawings/${fileId}/settings`);
        return response.data;
    } catch (error) {
        console.error(`Error getting display settings for drawing ${fileId}:`, error);
        // Return default settings on error
        return {
            showNonWorkAreaElements: true,
            showWorkAreaBoundaries: true,
            enableSegmentSelection: true,
            nonWorkAreaOpacity: 0.5,
            segmentSelectionMode: 'single',
            displayMode: 'normal',
            theme: {
                statusColors: {
                    not_started: '#808080',
                    in_progress: '#FFA500',
                    delayed: '#FF0000',
                    completed: '#00FF00'
                }
            }
        };
    }
};

/**
 * Update display settings for a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {Object} settings - New display settings
 * @returns {Promise<Object>} - Response data
 */
export const updateDisplaySettings = async (fileId, settings) => {
    try {
        const response = await api.put(`/drawings/${fileId}/settings`, settings);
        return response.data;
    } catch (error) {
        console.error(`Error updating display settings for drawing ${fileId}:`, error);
        throw error;
    }
};

export default {
    configureDrawingLayers,
    configureDrawingWorkAreas,
    configureDrawingWorkTypes,
    finalizeDrawingConfiguration,
    createWorkArea,
    updateWorkArea,
    deleteWorkArea,
    getStandardWorkTypes,
    createWorkType,
    updateWorkType,
    deleteWorkType,
    assignWorkType,
    removeWorkTypeAssignment,
    updateWorkDimensions,
    getWorkArea,
    getWorkAreas,
    getWorkTypes,
    updateElementStatus,
    updateElementSegment,
    updateMultipleSegments,
    getElementSegments,
    getDisplaySettings,
    updateDisplaySettings
};
