import axios from 'axios';

// Create an axios instance with default settings
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor for adding auth token, etc.
api.interceptors.request.use(
  (config) => {
    // You could add authentication tokens here if needed
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for error handling
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle common errors here
    if (error.response) {
      // Server responded with an error status
      console.error('API Error:', error.response.status, error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('API Request Error:', error.request);
    } else {
      // Something else happened while setting up the request
      console.error('API Setup Error:', error.message);
    }
    return Promise.reject(error);
  }
);

/**
 * Check API health status
 * @returns {Promise<Object>} - Health status information
 */
export const checkHealth = async () => {
  try {
    const response = await api.get('/health');
    return response.data;
  } catch (error) {
    console.error('Health check failed:', error);
    throw error;
  }
};

/**
 * Fetch all drawings
 * @param {Object} filters - Optional filters (project, status, date_from, date_to)
 * @param {number} limit - Maximum number of drawings to return (default: 50, max: 100)
 * @param {number} skip - Number of drawings to skip for pagination
 * @param {string} sortBy - Field to sort by (default: upload_date)
 * @param {number} sortOrder - Sort order: -1 for descending, 1 for ascending
 * @returns {Promise<Object>} - Response with drawings data
 */
export const fetchDrawings = async (filters = {}, limit = 50, skip = 0, sortBy = 'upload_date', sortOrder = -1) => {
  try {
    const params = {
      ...filters,
      limit,
      skip,
      sort_by: sortBy,
      sort_order: sortOrder
    };

    const response = await api.get('/drawings', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching drawings:', error);
    throw error;
  }
};

/**
 * Fetch a specific drawing by ID
 * @param {string} fileId - The ID of the drawing
 * @param {boolean} includeEntities - Whether to include entities in the response
 * @param {boolean} includeMetrics - Whether to include metrics in the response
 * @returns {Promise<Object>} - Response with drawing data
 */
export const fetchDrawing = async (fileId, includeEntities = true, includeMetrics = true) => {
  try {
    const response = await api.get(`/drawings/${fileId}`, {
      params: {
        include_entities: includeEntities,
        include_metrics: includeMetrics
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Fetch lightweight version of a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Lightweight drawing data
 */
export const fetchLightweightDrawing = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/lightweight`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching lightweight drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Upload a DXF file
 * @param {File} file - The DXF file to upload
 * @param {Object} metadata - Optional metadata about the file (name, project, description, etc.)
 * @returns {Promise<Object>} - Response with upload result
 */
export const uploadDxf = async (file, metadata = {}) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    if (metadata) {
      formData.append('metadata', JSON.stringify(metadata));
    }

    const response = await api.post('/drawings/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error uploading DXF file:', error);
    throw error;
  }
};

/**
 * Update entity status and color
 * @param {string} fileId - The ID of the drawing
 * @param {string} entityId - The ID of the entity
 * @param {string} status - New status (not_started, in_progress, delayed, completed)
 * @param {string} color - New color (hex format)
 * @returns {Promise<Object>} - Response with updated metrics
 */
export const updateEntityStatus = async (fileId, entityId, status, color) => {
  try {
    const response = await api.patch(`/drawings/${fileId}/entities`, {
      id: entityId,
      status,
      color
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating entity status for ${entityId}:`, error);
    throw error;
  }
};

/**
 * Update status for multiple elements
 * @param {string} fileId - The ID of the drawing
 * @param {Object} updateData - Object with element_ids, status, and color
 * @returns {Promise<Object>} - Response with updated metrics
 */
export const updateElementsStatus = async (fileId, updateData) => {
  try {
    const response = await api.patch(`/drawings/${fileId}/elements/status`, updateData);
    return response.data;
  } catch (error) {
    console.error(`Error updating multiple elements status for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Fetch metrics for a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {boolean} recalculate - Whether to recalculate metrics
 * @returns {Promise<Object>} - Response with metrics
 */
export const fetchMetrics = async (fileId, recalculate = false) => {
  try {
    const response = await api.get(`/drawings/${fileId}/metrics`, {
      params: { recalculate }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching metrics for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Generate a report for a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {string} format - Report format (json or csv)
 * @returns {Promise<Object>} - Response with report data
 */
export const generateReport = async (fileId, format = 'json') => {
  try {
    const response = await api.get(`/drawings/${fileId}/report`, {
      params: { format }
    });
    return response.data;
  } catch (error) {
    console.error(`Error generating report for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Download original DXF file
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Blob>} - Binary file data
 */
export const downloadDrawing = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/download`, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.error(`Error downloading drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Create a new version of a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {Object} versionData - Version details (name, description)
 * @returns {Promise<Object>} - Created version information
 */
export const createDrawingVersion = async (fileId, versionData) => {
  try {
    const response = await api.post(`/drawings/${fileId}/versions`, versionData);
    return response.data;
  } catch (error) {
    console.error(`Error creating version for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get all versions of a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - Array of version objects
 */
export const getDrawingVersions = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/versions`);
    return response.data;
  } catch (error) {
    console.error(`Error getting versions for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get layers information for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - Array of layer objects
 */
export const getDrawingLayers = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/layers`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching layers for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * ALIAS for getDrawingLayers for backward compatibility
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - Array of layer objects
 */
export const fetchLayers = async (fileId) => {
  return getDrawingLayers(fileId);
};

/**
 * Update work area progress
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @param {string} status - New status
 * @returns {Promise<Object>} - Response data with updated metrics
 */
export const updateWorkAreaProgress = async (fileId, workAreaId, status) => {
  try {
    const response = await api.patch(`/drawings/${fileId}/workareas/${workAreaId}/status`, {
      status
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating work area status for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Update work type progress for a work area
 * @param {string} fileId - The ID of the drawing
 * @param {string} workAreaId - The ID of the work area
 * @param {string} workTypeId - The ID of the work type
 * @param {number} completedQuantity - The completed quantity
 * @returns {Promise<Object>} - Response data with updated progress
 */
export const updateWorkTypeProgress = async (fileId, workAreaId, workTypeId, completedQuantity) => {
  try {
    const response = await api.patch(`/drawings/${fileId}/workareas/${workAreaId}/progress`, {
      work_type_id: workTypeId,
      completed_quantity: completedQuantity
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating work type progress for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get work completion report
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Report data
 */
export const getWorkCompletionReport = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/report/completion`);
    return response.data;
  } catch (error) {
    console.error(`Error getting work completion report for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get cost report
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Report data
 */
export const getCostReport = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/report/cost`);
    return response.data;
  } catch (error) {
    console.error(`Error getting cost report for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get SVG representation of a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<string>} - SVG content
 */
export const getDrawingSvg = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/svg`, {
      responseType: 'text'
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting SVG for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get work quantities for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Quantities data
 */
export const getWorkQuantities = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/quantities`);
    return response.data;
  } catch (error) {
    console.error(`Error getting work quantities for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get project timeline
 * @param {string} fileId - The ID of the drawing
 * @param {string} startDate - Optional start date
 * @param {string} plannedEndDate - Optional planned end date
 * @returns {Promise<Object>} - Timeline data
 */
export const getProjectTimeline = async (fileId, startDate, plannedEndDate) => {
  try {
    const response = await api.get(`/drawings/${fileId}/timeline`, {
      params: { start_date: startDate, planned_end_date: plannedEndDate }
    });
    return response.data;
  } catch (error) {
    console.error(`Error getting project timeline for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Share a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {Object} shareDetails - Details about the share
 * @returns {Promise<Object>} - Share information
 */
export const shareDrawing = async (fileId, shareDetails) => {
  try {
    const response = await api.post(`/share/drawing/${fileId}`, shareDetails);
    return response.data;
  } catch (error) {
    console.error(`Error sharing drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get shared drawing information
 * @param {string} shareToken - The share token
 * @returns {Promise<Object>} - Shared drawing data
 */
export const getSharedDrawing = async (shareToken) => {
  try {
    const response = await api.get(`/share/${shareToken}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting shared drawing with token ${shareToken}:`, error);
    throw error;
  }
};

// Re-export specific functions from configurationApi
export { 
    getDisplaySettings, 
    updateDisplaySettings,
    updateElementStatus,
    updateElementSegment,
    updateMultipleSegments 
} from './configurationApi';

// Export the axios instance for direct use if needed
export default api;
