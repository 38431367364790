import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Alert,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
  LinearProgress,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputAdornment,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {
  ZoomIn,
  ZoomOut,
  PanTool,
  Refresh,
  Layers,
  Close,
  Info,
  BrushOutlined,
  ColorLensOutlined,
  ViewInAr,
  FormatListBulleted,
  BarChart,
  RotateLeft,
  CropFree,
  Save,
  ExpandMore,
  ExpandLess,
  Code,
  Check,
  Warning,
  Error as ErrorIcon,
  AttachMoney,
  Construction,
  Work,
  AccountTree,
  DoneAll,
  DoNotDisturbAlt,
  Pending,
  Visibility,
  VisibilityOff,
  TrendingDown,
  TrendingUp,
  HighlightAlt,
  StraightenOutlined,
  ContentCut
} from '@mui/icons-material';
import { Stage, Layer, Line, Circle, Group, Text, Ellipse, Arc, Shape, Rect } from 'react-konva';
import { debounce } from 'lodash';

// Import custom components
import ViewerToolbar from './ViewerToolbar';
import EntityInfoPanel from './EntityInfoPanel';

// Import API service
import {
  fetchDrawing,
  fetchLightweightDrawing,
  updateWorkAreaProgress,
  updateWorkTypeProgress,
  updateElementStatus,
  updateElementSegment,
  updateMultipleSegments,
  getCostReport,
  getWorkCompletionReport,
  getDisplaySettings,
  updateDisplaySettings
} from '../services/api';
import { useAppContext } from '../context/AppContext';

// Constants for status colors and labels
const STATUS_COLORS = {
  not_started: '#808080', // Grey
  in_progress: '#FFA500', // Orange
  delayed: '#FF0000',     // Red
  completed: '#00FF00'    // Green
};

const STATUS_LABELS = {
  not_started: 'Not Started',
  in_progress: 'In Progress',
  delayed: 'Delayed',
  completed: 'Completed'
};

/**
 * DxfViewer Component
 *
 * This component displays the lightweight version of DXF drawings
 * and provides interactive features for updating work progress.
 */
const DxfViewer = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useAppContext();
  const [drawing, setDrawing] = useState(null);
  const [lightweightData, setLightweightData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewportSize, setViewportSize] = useState({ width: 800, height: 600 });
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedWorkArea, setSelectedWorkArea] = useState(null);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [layersVisible, setLayersVisible] = useState({});
  const [metrics, setMetrics] = useState(null);
  const [costReport, setCostReport] = useState(null);
  const [completionReport, setCompletionReport] = useState(null);
  const [notification, setNotification] = useState(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [toolbarHeight, setToolbarHeight] = useState(48); // Динамическая высота тулбара

  // New state for display settings
  const [displaySettings, setDisplaySettings] = useState({
    showNonWorkAreaElements: true,
    showWorkAreaBoundaries: true,
    enableSegmentSelection: true,
    nonWorkAreaOpacity: 0.5,
    segmentSelectionMode: 'single',
    displayMode: 'normal'
  });

  // New state for segment selection
  const [selectionMode, setSelectionMode] = useState('element'); // 'element', 'segment', or 'none'
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [segmentDialogOpen, setSegmentDialogOpen] = useState(false);
  const [segmentUpdateForm, setSegmentUpdateForm] = useState({
    status: 'not_started',
    completion: 0
  });

  // State for work area filters
  const [filterByStatus, setFilterByStatus] = useState(null);
  const [filterByCategory, setFilterByCategory] = useState(null);

  // Canvas refs
  const containerRef = useRef(null);
  const stageRef = useRef(null);
  const toolbarRef = useRef(null);

  // Form state for progress update
  const [progressForm, setProgressForm] = useState({
    workTypeId: '',
    completedQuantity: '',
  });

  // Load drawing data
  useEffect(() => {
    const loadDrawing = async () => {
      try {
        setLoading(true);

        // First load basic drawing info
        const drawingData = await fetchDrawing(fileId, false);
        setDrawing(drawingData);

        // Check if drawing is configured
        if (!drawingData.is_configured) {
          // Redirect to configuration wizard
          setLoading(false);
          showNotification('This drawing needs to be configured first', 'warning');
          navigate(`/configure/${fileId}`);
          return;
        }

        // Load lightweight data
        const lwData = await fetchLightweightDrawing(fileId);
        setLightweightData(lwData);

        // Load reports
        try {
          const costData = await getCostReport(fileId);
          setCostReport(costData);

          const completionData = await getWorkCompletionReport(fileId);
          setCompletionReport(completionData);
        } catch (reportError) {
          console.error('Error loading reports:', reportError);
          // Continue even if reports fail to load
        }

        // Initialize visible layers
        const selectedLayers = lwData.metadata?.selectedLayers || [];
        const layerVisibility = {};

        selectedLayers.forEach(layer => {
          layerVisibility[layer] = true;
        });

        setLayersVisible(layerVisibility);
        
        // Load display settings
        try {
          const settings = await getDisplaySettings(fileId);
          setDisplaySettings(settings);
        } catch (settingsError) {
          console.error('Error loading display settings:', settingsError);
          // Continue with default settings
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading drawing:', err);
        setError('Failed to load drawing data. Please try again.');
        setLoading(false);
      }
    };

    if (fileId) {
      loadDrawing();
    }
  }, [fileId, navigate, showNotification]);

  // Adjust viewport when container size changes
  useEffect(() => {
    const updateViewportSize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        
        // Получаем актуальную высоту тулбара, если возможно
        let actualToolbarHeight = toolbarHeight;
        if (toolbarRef.current) {
          actualToolbarHeight = toolbarRef.current.clientHeight;
          setToolbarHeight(actualToolbarHeight);
        }
        
        // Устанавливаем размеры с учетом реальной высоты тулбара и небольшого дополнительного отступа
        setViewportSize({
          width: clientWidth,
          height: clientHeight - (actualToolbarHeight + 10) // Добавляем небольшой отступ
        });
      }
    };

    updateViewportSize();

    const resizeObserver = new ResizeObserver(updateViewportSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Auto-fit drawing to viewport when first loaded
  useEffect(() => {
    if (lightweightData && viewportSize.width > 0) {
      fitDrawingToViewport();
    }
  }, [lightweightData, viewportSize]);

  // Optimized functions
  const debouncedSetPosition = useCallback(debounce((newPos) => {
    setPosition(newPos);
  }, 5), []);

  const debouncedSetScale = useCallback(debounce((newScale) => {
    setScale(newScale);
  }, 5), []);

  // Check if an element is in the current viewport
  const isElementVisible = useCallback((element) => {
    // Define viewport boundaries in model coordinates
    const vpMinX = -position.x / scale;
    const vpMinY = -position.y / scale;
    const vpMaxX = (viewportSize.width - position.x) / scale;
    const vpMaxY = (viewportSize.height - position.y) / scale;

    // Add padding to viewport
    const padding = 200; // Units in model space

    // Check element points
    if (element.points && element.points.length > 0) {
      return element.points.some(p =>
        p.x >= vpMinX - padding && p.x <= vpMaxX + padding &&
        p.y >= vpMinY - padding && p.y <= vpMaxY + padding
      );
    }

    // Check center for circles
    if (element.center) {
      const radius = element.radius || 0;
      return element.center.x + radius >= vpMinX - padding &&
        element.center.x - radius <= vpMaxX + padding &&
        element.center.y + radius >= vpMinY - padding &&
        element.center.y - radius <= vpMaxY + padding;
    }

    // Check position for other elements
    if (element.position) {
      return element.position.x >= vpMinX - padding &&
        element.position.x <= vpMaxX + padding &&
        element.position.y >= vpMinY - padding &&
        element.position.y <= vpMaxY + padding;
    }

    return true; // If no coordinates to check, consider it visible
  }, [position, scale, viewportSize]); // Include dependencies

  // Filter work areas based on current filters
  const filteredWorkAreas = useMemo(() => {
    if (!lightweightData || !lightweightData.workAreas) return [];

    return lightweightData.workAreas.filter(area => {
      // Filter by status if active
      if (filterByStatus && area.status !== filterByStatus) {
        return false;
      }

      // Filter by category if active
      if (filterByCategory && area.category !== filterByCategory) {
        return false;
      }

      return true;
    });
  }, [lightweightData, filterByStatus, filterByCategory]);

  // Get visible elements based on selected work areas and viewport
  const visibleElements = useMemo(() => {
    if (!lightweightData || !lightweightData.elements) return [];

    // Filter by layer visibility and viewport
    return lightweightData.elements.filter(element => {
      // Check if element's layer is visible
      const elementLayer = element.layer || '0';
      if (!layersVisible[elementLayer]) return false;

      // Check if element is in a work area or if we're showing non-work-area elements
      if (!element.workAreaId && !displaySettings.showNonWorkAreaElements) return false;
      
      // Check if element is in viewport
      return isElementVisible(element);
    });
  }, [lightweightData, layersVisible, isElementVisible, displaySettings.showNonWorkAreaElements]);

  // Calculate metrics based on work areas
  const calculateMetrics = useMemo(() => {
    if (!lightweightData || !lightweightData.workAreas) {
      return {
        completedCount: 0,
        inProgressCount: 0,
        delayedCount: 0,
        notStartedCount: 0,
        totalCount: 0,
        completedPercentage: 0
      };
    }

    const workAreas = lightweightData.workAreas;
    const total = workAreas.length;

    // Count by status
    const completed = workAreas.filter(area => area.status === 'completed').length;
    const inProgress = workAreas.filter(area => area.status === 'in_progress').length;
    const delayed = workAreas.filter(area => area.status === 'delayed').length;
    const notStarted = workAreas.filter(area => area.status === 'not_started').length;

    // Calculate completion percentage
    const percentage = total > 0 ? Math.round((completed / total) * 100) : 0;

    return {
      completedCount: completed,
      inProgressCount: inProgress,
      delayedCount: delayed,
      notStartedCount: notStarted,
      totalCount: total,
      completedPercentage: percentage
    };
  }, [lightweightData]);

  // Get unique categories from work areas
  const workAreaCategories = useMemo(() => {
    if (!lightweightData || !lightweightData.workAreas) return [];

    const categories = new Set();
    lightweightData.workAreas.forEach(area => {
      if (area.category) {
        categories.add(area.category);
      }
    });

    return Array.from(categories);
  }, [lightweightData]);

  // Calculate bounds of the drawing
  const calculateBounds = () => {
    if (!lightweightData) {
      return { minX: 0, minY: 0, maxX: 100, maxY: 100 };
    }
    
    // Используем viewBox из lightweight data, если он доступен
    if (lightweightData.viewBox) {
      const [minX, minY, maxX, maxY] = lightweightData.viewBox;
      return { minX, minY, maxX, maxY };
    }
    
    // Если viewBox отсутствует, рассчитываем границы на основе элементов
    if (lightweightData.elements && lightweightData.elements.length > 0) {
      let minX = Infinity;
      let minY = Infinity;
      let maxX = -Infinity;
      let maxY = -Infinity;
      
      lightweightData.elements.forEach(element => {
        // Обрабатываем точки элемента
        if (element.points && element.points.length > 0) {
          element.points.forEach(point => {
            minX = Math.min(minX, point.x);
            minY = Math.min(minY, point.y);
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
          });
        }
        
        // Обрабатываем центр и радиус для круговых элементов
        if (element.center) {
          const radius = element.radius || 0;
          minX = Math.min(minX, element.center.x - radius);
          minY = Math.min(minY, element.center.y - radius);
          maxX = Math.max(maxX, element.center.x + radius);
          maxY = Math.max(maxY, element.center.y + radius);
        }
        
        // Обрабатываем position для других типов элементов
        if (element.position) {
          minX = Math.min(minX, element.position.x);
          minY = Math.min(minY, element.position.y);
          maxX = Math.max(maxX, element.position.x);
          maxY = Math.max(maxY, element.position.y);
        }
      });
      
      // Проверяем валидность границ
      if (minX !== Infinity && minY !== Infinity && maxX !== -Infinity && maxY !== -Infinity) {
        return { minX, minY, maxX, maxY };
      }
    }
    
    // Если не удалось рассчитать границы, возвращаем значения по умолчанию
    return { minX: 0, minY: 0, maxX: 100, maxY: 100 };
  };

  // Fit drawing to viewport
  const fitDrawingToViewport = () => {
    if (!lightweightData) return;

    // Use bounds from viewBox or calculate them
    const bounds = calculateBounds();
    const { minX, minY, maxX, maxY } = bounds;

    const drawingWidth = maxX - minX;
    const drawingHeight = maxY - minY;

    if (drawingWidth === 0 || drawingHeight === 0) return;

    // Увеличиваем коэффициент масштабирования для лучшего отображения
    const scaleX = viewportSize.width / (drawingWidth * 1.2);
    const scaleY = viewportSize.height / (drawingHeight * 1.2);
    const newScale = Math.min(scaleX, scaleY);

    const drawingCenterX = (minX + maxX) / 2;
    const drawingCenterY = (minY + maxY) / 2;

    const newPosition = {
      x: viewportSize.width / 2 - drawingCenterX * newScale,
      y: viewportSize.height / 2 - drawingCenterY * newScale
    };

    setScale(newScale);
    setPosition(newPosition);
  };

  // Handle mouse wheel for zooming
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const stage = stageRef.current;
    if (!stage) return;

    const oldScale = scale;
    const pointerPos = stage.getPointerPosition();
    if (!pointerPos) return;

    const mousePointTo = {
      x: pointerPos.x / oldScale - position.x / oldScale,
      y: pointerPos.y / oldScale - position.y / oldScale
    };

    // Scale factor: zoom in or out
    const scaleBy = 1.1;
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Limit scale range
    const limitedScale = Math.max(0.01, Math.min(100, newScale));

    const newPos = {
      x: -(mousePointTo.x - pointerPos.x / limitedScale) * limitedScale,
      y: -(mousePointTo.y - pointerPos.y / limitedScale) * limitedScale
    };

    debouncedSetScale(limitedScale);
    debouncedSetPosition(newPos);
  };

  // Handle mouse down for panning
  const handleMouseDown = (e) => {
    // Активируем панинг только если:
    // 1. Нажата левая кнопка мыши (e.evt.button === 0)
    // 2. Режим панинга активен ИЛИ зажата клавиша пробел
    if (e.evt.button === 0 && (isPanning || e.evt.ctrlKey)) {
      e.evt.preventDefault();
      e.cancelBubble = true; // Останавливаем распространение события
      document.body.style.cursor = 'grabbing';
    }
  };

  // Handle mouse move for panning
  const handleMouseMove = (e) => {
    if (e.evt.buttons === 1 && (isPanning || e.evt.ctrlKey)) {
      const dx = e.evt.movementX;
      const dy = e.evt.movementY;

      debouncedSetPosition({
        x: position.x + dx,
        y: position.y + dy
      });
    }
  };

  // Handle mouse up to stop panning
  const handleMouseUp = () => {
    document.body.style.cursor = 'default';
  };

  // Handle element click
  const handleElementClick = (element) => {
    // Игнорируем клики, если активен режим панинга
    if (isPanning) return;
    
    if (selectionMode === 'element') {
      setSelectedElement(element);
      setSelectedSegments([]);

      // Find the work area this element belongs to
      if (element.workAreaId && lightweightData && lightweightData.workAreas) {
        const workArea = lightweightData.workAreas.find(area => area.id === element.workAreaId);
        if (workArea) {
          setSelectedWorkArea(workArea);
        }
      }

      setInfoDrawerOpen(true);
      setActiveTab(0); // Switch to info tab
    }
  };

  // Handle segment click
  const handleSegmentClick = (element, segment, event) => {
    // Игнорируем клики, если активен режим панинга
    if (isPanning) return;
    
    // Остановка распространения события, чтобы не вызвать handleElementClick
    if (event) {
      event.cancelBubble = true;
    }
    
    if (selectionMode !== 'segment') return;
    
    // Handle segment selection
    if (displaySettings.segmentSelectionMode === 'single') {
      // Single selection mode - replace selection
      setSelectedSegments([{ elementId: element.id, segment }]);
    } else {
      // Multiple selection mode - toggle selection
      setSelectedSegments(prev => {
        const isSelected = prev.some(s => s.elementId === element.id && s.segment.id === segment.id);
        
        if (isSelected) {
          return prev.filter(s => !(s.elementId === element.id && s.segment.id === segment.id));
        } else {
          return [...prev, { elementId: element.id, segment }];
        }
      });
    }
    
    // Show selected element in info panel
    if (!selectedElement || selectedElement.id !== element.id) {
      setSelectedElement(element);
      
      if (element.workAreaId && lightweightData && lightweightData.workAreas) {
        const workArea = lightweightData.workAreas.find(area => area.id === element.workAreaId);
        if (workArea) {
          setSelectedWorkArea(workArea);
        }
      }
    }
    
    setInfoDrawerOpen(true);
    setActiveTab(0);
  };

  // Handle work area click
  const handleWorkAreaClick = (workArea, event) => {
    // Игнорируем клики, если активен режим панинга
    if (isPanning) return;
    
    // Останавливаем распространение события
    if (event) {
      event.cancelBubble = true;
    }
    
    setSelectedWorkArea(workArea);
    setSelectedElement(null);
    setSelectedSegments([]);
    setInfoDrawerOpen(true);
    setActiveTab(0); // Switch to info tab
  };

  // Handle status update for a work area
  const handleWorkAreaStatusChange = async (workAreaId, newStatus) => {
    try {
      // Call API to update work area status
      const result = await updateWorkAreaProgress(fileId, workAreaId, newStatus);

      // Update local state
      if (lightweightData && lightweightData.workAreas) {
        setLightweightData(prev => ({
          ...prev,
          workAreas: prev.workAreas.map(area =>
            area.id === workAreaId ? { ...area, status: newStatus } : area
          )
        }));
      }

      // Update selected work area if it's the one we changed
      if (selectedWorkArea && selectedWorkArea.id === workAreaId) {
        setSelectedWorkArea(prev => ({ ...prev, status: newStatus }));
      }

      // Show notification
      setNotification({
        message: `Status updated to ${STATUS_LABELS[newStatus]}`,
        type: 'success'
      });

      // Close dialog
      setStatusDialogOpen(false);
    } catch (err) {
      console.error('Error updating work area status:', err);
      setNotification({
        message: 'Failed to update status. Please try again.',
        type: 'error'
      });
    }
  };

  // Handle progress update for a work type
  const handleWorkTypeProgressUpdate = async () => {
    if (!selectedWorkArea || !progressForm.workTypeId || !progressForm.completedQuantity) {
      setNotification({
        message: 'Please fill all required fields',
        type: 'warning'
      });
      return;
    }

    try {
      // Call API to update work type progress
      const result = await updateWorkTypeProgress(
        fileId,
        selectedWorkArea.id,
        progressForm.workTypeId,
        parseFloat(progressForm.completedQuantity)
      );

      // Update local state
      if (lightweightData && lightweightData.workAreas) {
        setLightweightData(prev => ({
          ...prev,
          workAreas: prev.workAreas.map(area => {
            if (area.id === selectedWorkArea.id) {
              return {
                ...area,
                progress: result.overall_progress,
                status: result.status,
                work_types: (area.work_types || []).map(wt => {
                  if (wt.work_type_id === progressForm.workTypeId) {
                    return {
                      ...wt,
                      completed_quantity: parseFloat(progressForm.completedQuantity)
                    };
                  }
                  return wt;
                })
              };
            }
            return area;
          })
        }));
      }

      // Update selected work area if it's the one we changed
      if (selectedWorkArea && selectedWorkArea.id === result.work_area_id) {
        setSelectedWorkArea(prev => ({
          ...prev,
          progress: result.overall_progress,
          status: result.status,
          work_types: (prev.work_types || []).map(wt => {
            if (wt.work_type_id === progressForm.workTypeId) {
              return {
                ...wt,
                completed_quantity: parseFloat(progressForm.completedQuantity)
              };
            }
            return wt;
          })
        }));
      }

      // Show notification
      setNotification({
        message: `Progress updated successfully. Overall progress: ${result.overall_progress.toFixed(1)}%`,
        type: 'success'
      });

      // Close dialog
      setProgressDialogOpen(false);
    } catch (err) {
      console.error('Error updating work type progress:', err);
      setNotification({
        message: 'Failed to update progress. Please try again.',
        type: 'error'
      });
    }
  };
  
  // Handle segment status update
  const handleSegmentStatusUpdate = async () => {
    if (selectedSegments.length === 0) {
      setNotification({
        message: 'No segments selected',
        type: 'warning'
      });
      return;
    }
    
    try {
      if (selectedSegments.length === 1) {
        // Update single segment
        const {elementId, segment} = selectedSegments[0];
        
        await updateElementSegment(
          fileId,
          elementId,
          segment.id,
          {
            status: segmentUpdateForm.status,
            completion: segmentUpdateForm.status === 'completed' ? 100 : segmentUpdateForm.completion
          }
        );
      } else {
        // Update multiple segments
        const segmentUpdates = selectedSegments.map(({elementId, segment}) => ({
          id: segment.id,
          status: segmentUpdateForm.status,
          completion: segmentUpdateForm.status === 'completed' ? 100 : segmentUpdateForm.completion
        }));
        
        // Group by element ID for multiple API calls
        const updatesByElement = {};
        selectedSegments.forEach(({elementId, segment}) => {
          if (!updatesByElement[elementId]) {
            updatesByElement[elementId] = [];
          }
          
          updatesByElement[elementId].push({
            id: segment.id,
            status: segmentUpdateForm.status,
            completion: segmentUpdateForm.status === 'completed' ? 100 : segmentUpdateForm.completion
          });
        });
        
        // Update each element's segments
        await Promise.all(
          Object.entries(updatesByElement).map(([elementId, segments]) => 
            updateMultipleSegments(fileId, elementId, segments)
          )
        );
      }
      
      // Update local state - refresh data
      const lwData = await fetchLightweightDrawing(fileId);
      setLightweightData(lwData);
      
      // Update selected element if needed
      if (selectedElement) {
        const updatedElement = lwData.elements.find(el => el.id === selectedElement.id);
        if (updatedElement) {
          setSelectedElement(updatedElement);
        }
      }
      
      // Show success notification
      setNotification({
        message: `Updated ${selectedSegments.length} segment${selectedSegments.length > 1 ? 's' : ''}`,
        type: 'success'
      });
      
      // Close dialog
      setSegmentDialogOpen(false);
      
      // Clear selection if single mode
      if (displaySettings.segmentSelectionMode === 'single') {
        setSelectedSegments([]);
      }
      
    } catch (err) {
      console.error('Error updating segment status:', err);
      setNotification({
        message: 'Failed to update segment status. Please try again.',
        type: 'error'
      });
    }
  };

  // Open status dialog
  const openStatusDialog = (workArea) => {
    setSelectedWorkArea(workArea);
    setStatusDialogOpen(true);
  };

  // Open progress update dialog
  const openProgressDialog = (workArea) => {
    setSelectedWorkArea(workArea);
    // Set default work type if available
    if (workArea.work_types && workArea.work_types.length > 0) {
      setProgressForm({
        workTypeId: workArea.work_types[0].work_type_id,
        completedQuantity: workArea.work_types[0].completed_quantity || 0
      });
    } else {
      setProgressForm({
        workTypeId: '',
        completedQuantity: ''
      });
    }
    setProgressDialogOpen(true);
  };
  
  // Open segment update dialog
  const openSegmentUpdateDialog = () => {
    if (selectedSegments.length === 0) {
      setNotification({
        message: 'Please select at least one segment first',
        type: 'warning'
      });
      return;
    }
    
    // If there's only one segment selected, use its current status
    if (selectedSegments.length === 1) {
      const segment = selectedSegments[0].segment;
      setSegmentUpdateForm({
        status: segment.status || 'not_started',
        completion: segment.completion || 0
      });
    } else {
      // Default values for multiple segments
      setSegmentUpdateForm({
        status: 'not_started',
        completion: 0
      });
    }
    
    setSegmentDialogOpen(true);
  };

  // Toggle selection mode
  const toggleSelectionMode = (mode) => {
    // Исправление переключения режимов выбора
    if (selectionMode === mode) {
      // Если уже активен режим 'element', оставляем его активным
      // Иначе переключаемся на режим 'element' как на режим по умолчанию
      if (mode === 'element') {
        return; // Оставляем element как активный режим
      } else {
        setSelectionMode('element');
      }
    } else {
      setSelectionMode(mode);
    }
    
    // Clear selections when changing modes
    if (mode === 'element') {
      setSelectedSegments([]);
    } else if (mode === 'segment') {
      // При переключении на режим segment сохраняем выбранный элемент
      // но очищаем выбранные сегменты
      setSelectedSegments([]);
    }
  };

  // Get color for a work area based on status
  const getWorkAreaColor = (workArea) => {
    if (!workArea) return STATUS_COLORS.not_started;
    return STATUS_COLORS[workArea.status] || STATUS_COLORS.not_started;
  };

  // Get color for an element based on work area status
  const getElementColor = (element) => {
    if (!element) return '#AAAAAA';
    
    // Use status color for element with work area
    if (element.workAreaId) {
      // Get color based on status
      return STATUS_COLORS[element.status || 'not_started'] || '#AAAAAA';
    }
    
    // For elements without work area, use grey with reduced opacity
    return '#AAAAAA';
  };
  
  // Get color for a segment based on its status
  const getSegmentColor = (segment, element) => {
    // If segment has its own status, use it
    if (segment && segment.status) {
      return STATUS_COLORS[segment.status] || '#AAAAAA';
    }
    
    // Otherwise, use element's status color
    return getElementColor(element);
  };
  
  // Check if a segment is selected
  const isSegmentSelected = (elementId, segmentId) => {
    return selectedSegments.some(s => 
      s.elementId === elementId && s.segment.id === segmentId
    );
  };

  // Handler for toggling layer visibility
  const toggleLayer = (layer) => {
    setLayersVisible(prev => ({
      ...prev,
      [layer]: !prev[layer]
    }));
  };

  // Handler for toggling filter by status
  const toggleStatusFilter = (status) => {
    setFilterByStatus(prev => prev === status ? null : status);
  };

  // Handler for toggling filter by category
  const toggleCategoryFilter = (category) => {
    setFilterByCategory(prev => prev === category ? null : category);
  };
  
  // Handler for toggling visibility of non-work-area elements
  const toggleNonWorkAreaElements = () => {
    const newSetting = !displaySettings.showNonWorkAreaElements;
    setDisplaySettings(prev => ({
      ...prev,
      showNonWorkAreaElements: newSetting
    }));
    
    // Also save settings to server
    updateDisplaySettings(fileId, {
      ...displaySettings,
      showNonWorkAreaElements: newSetting
    }).catch(err => {
      console.error('Error saving display settings:', err);
    });
  };
  
  // Handler for changing segment selection mode
  const changeSegmentSelectionMode = (mode) => {
    setDisplaySettings(prev => ({
      ...prev,
      segmentSelectionMode: mode
    }));
    
    // Clear selections when changing modes
    setSelectedSegments([]);
    
    // Save settings
    updateDisplaySettings(fileId, {
      ...displaySettings,
      segmentSelectionMode: mode
    }).catch(err => {
      console.error('Error saving display settings:', err);
    });
  };

  // Get work type by ID
  const getWorkTypeById = (id) => {
    if (!lightweightData || !lightweightData.workTypes) return null;
    return lightweightData.workTypes.find(wt => wt.id === id);
  };

  // Render an element
  const renderElement = (entity) => {
    // Base element properties
    const isSelected = selectedElement && selectedElement.id === entity.id;
    const inWorkArea = !!entity.workAreaId;
    const opacity = inWorkArea ? 1.0 : displaySettings.nonWorkAreaOpacity;
    const strokeWidth = (isSelected ? 2 : 1) / scale;
    const hitStrokeWidth = 10 / scale;
    
    // If this element has segments and segmentation is enabled
    if (entity.segments && entity.segments.length > 0 && displaySettings.enableSegmentSelection) {
      // Render each segment
      const segmentElements = entity.segments.map(segment => {
        // Determine segment properties
        const isSegmentSel = isSegmentSelected(entity.id, segment.id);
        const segColor = getSegmentColor(segment, entity);
        const segOpacity = inWorkArea ? 1.0 : displaySettings.nonWorkAreaOpacity;
        const segStrokeWidth = (isSegmentSel ? 3 : 1) / scale;
        
        // Render based on segment type
        switch (entity.type) {
          case 'LINE':
            if (entity.points && entity.points.length >= 2) {
              if (segment.startIndex !== undefined && segment.endIndex !== undefined) {
                const p1 = entity.points[segment.startIndex];
                const p2 = entity.points[segment.endIndex];
                
                return (
                  <Line
                    key={segment.id}
                    points={[p1.x, p1.y, p2.x, p2.y]}
                    stroke={segColor}
                    strokeWidth={segStrokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    opacity={segOpacity}
                    onClick={(e) => {
                      // Остановить распространение события
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                    onTap={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                  />
                );
              }
            }
            break;
            
          case 'POLYLINE':
            if (entity.points && entity.points.length >= 2) {
              if (segment.startIndex !== undefined && segment.endIndex !== undefined) {
                const p1 = entity.points[segment.startIndex];
                const p2 = entity.points[segment.endIndex];
                
                return (
                  <Line
                    key={segment.id}
                    points={[p1.x, p1.y, p2.x, p2.y]}
                    stroke={segColor}
                    strokeWidth={segStrokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    opacity={segOpacity}
                    onClick={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                    onTap={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                  />
                );
              }
            }
            break;
            
          case 'CIRCLE':
            if (entity.center && entity.radius) {
              if (segment.startAngle !== undefined && segment.endAngle !== undefined) {
                const startRad = (segment.startAngle * Math.PI) / 180;
                const endRad = (segment.endAngle * Math.PI) / 180;
                const angleRange = endRad - startRad;
                
                return (
                  <Arc
                    key={segment.id}
                    x={entity.center.x}
                    y={entity.center.y}
                    radius={entity.radius}
                    angle={(segment.endAngle - segment.startAngle)}
                    rotation={segment.startAngle}
                    stroke={segColor}
                    strokeWidth={segStrokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    opacity={segOpacity}
                    onClick={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                    onTap={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                  />
                );
              }
            }
            break;
            
          case 'ARC':
            if (entity.center && entity.radius) {
              if (segment.startAngle !== undefined && segment.endAngle !== undefined) {
                return (
                  <Arc
                    key={segment.id}
                    x={entity.center.x}
                    y={entity.center.y}
                    radius={entity.radius}
                    angle={(segment.endAngle - segment.startAngle)}
                    rotation={segment.startAngle}
                    stroke={segColor}
                    strokeWidth={segStrokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    opacity={segOpacity}
                    onClick={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                    onTap={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                  />
                );
              }
            }
            break;
            
          case 'ELLIPSE':
            if (entity.center && entity.majorRadius && entity.minorRadius) {
              if (segment.startAngle !== undefined && segment.endAngle !== undefined) {
                // We need a custom shape for elliptical arc
                const centerX = entity.center.x;
                const centerY = entity.center.y;
                const majorRadius = entity.majorRadius;
                const minorRadius = entity.minorRadius;
                const rotation = entity.rotation || 0;
                const startAngle = segment.startAngle;
                const endAngle = segment.endAngle;
                
                // We need to draw an elliptical arc - this is complex,
                // but for simplicity we can approximate with a path
                const startRad = (startAngle * Math.PI) / 180;
                const endRad = (endAngle * Math.PI) / 180;
                
                const startX = centerX + majorRadius * Math.cos(startRad);
                const startY = centerY + minorRadius * Math.sin(startRad);
                const endX = centerX + majorRadius * Math.cos(endRad);
                const endY = centerY + minorRadius * Math.sin(endRad);
                
                return (
                  <Line
                    key={segment.id}
                    points={[startX, startY, endX, endY]}
                    stroke={segColor}
                    strokeWidth={segStrokeWidth}
                    hitStrokeWidth={hitStrokeWidth}
                    opacity={segOpacity}
                    onClick={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                    onTap={(e) => {
                      e.cancelBubble = true;
                      handleSegmentClick(entity, segment, e);
                    }}
                  />
                );
              }
            }
            break;
            
          default:
            // For other types or fallback, render a simple marker for the segment
            return (
              <Circle
                key={segment.id}
                x={entity.center?.x || entity.position?.x || 0}
                y={entity.center?.y || entity.position?.y || 0}
                radius={5 / scale}
                fill={segColor}
                opacity={segOpacity}
                onClick={(e) => {
                  e.cancelBubble = true;
                  handleSegmentClick(entity, segment, e);
                }}
                onTap={(e) => {
                  e.cancelBubble = true;
                  handleSegmentClick(entity, segment, e);
                }}
              />
            );
        }
        
        return null;
      }).filter(Boolean); // Filter out nulls
      
      if (segmentElements.length > 0) {
        return (
          <Group key={entity.id}>
            {segmentElements}
          </Group>
        );
      }
    }
    
    // Fallback to rendering whole element if no segments or segmentation disabled
    const color = getElementColor(entity);
    
    // Render different types of entities
    switch (entity.type) {
      case 'LINE':
        if (!entity.points || entity.points.length < 2) return null;
        return (
          <Line
            key={entity.id}
            points={entity.points.flatMap(p => [p.x, p.y])}
            stroke={color}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            opacity={opacity}
            onClick={() => handleElementClick(entity)}
            onTap={() => handleElementClick(entity)}
          />
        );

      case 'POLYLINE':
        if (!entity.points || entity.points.length < 2) return null;
        return (
          <Line
            key={entity.id}
            points={entity.points.flatMap(p => [p.x, p.y])}
            stroke={color}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            closed={entity.closed}
            opacity={opacity}
            onClick={() => handleElementClick(entity)}
            onTap={() => handleElementClick(entity)}
          />
        );

      case 'CIRCLE':
        if (!entity.center || !entity.radius) return null;
        return (
          <Circle
            key={entity.id}
            x={entity.center.x}
            y={entity.center.y}
            radius={entity.radius}
            stroke={color}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            opacity={opacity}
            onClick={() => handleElementClick(entity)}
            onTap={() => handleElementClick(entity)}
          />
        );

      case 'ARC':
        if (!entity.center || !entity.radius) return null;
        return (
          <Arc
            key={entity.id}
            x={entity.center.x}
            y={entity.center.y}
            radius={entity.radius}
            angle={entity.endAngle - entity.startAngle}
            rotation={entity.startAngle}
            stroke={color}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            opacity={opacity}
            onClick={() => handleElementClick(entity)}
            onTap={() => handleElementClick(entity)}
          />
        );

      case 'ELLIPSE':
        if (!entity.center) return null;
        return (
          <Ellipse
            key={entity.id}
            x={entity.center.x}
            y={entity.center.y}
            radiusX={entity.majorRadius || 5}
            radiusY={entity.minorRadius || 3}
            stroke={color}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            rotation={entity.rotation || 0}
            opacity={opacity}
            onClick={() => handleElementClick(entity)}
            onTap={() => handleElementClick(entity)}
          />
        );

      default:
        // Try to render based on available properties
        if (entity.points && entity.points.length > 0) {
          return (
            <Line
              key={entity.id}
              points={entity.points.flatMap(p => [p.x, p.y])}
              stroke={color}
              strokeWidth={strokeWidth}
              hitStrokeWidth={hitStrokeWidth}
              opacity={opacity}
              onClick={() => handleElementClick(entity)}
              onTap={() => handleElementClick(entity)}
            />
          );
        }

        if (entity.position) {
          return (
            <Circle
              key={entity.id}
              x={entity.position.x}
              y={entity.position.y}
              radius={3 / scale}
              fill={color}
              stroke={color}
              strokeWidth={strokeWidth}
              hitStrokeWidth={hitStrokeWidth}
              opacity={opacity}
              onClick={() => handleElementClick(entity)}
              onTap={() => handleElementClick(entity)}
            />
          );
        }

        return null;
    }
  };

  // Render a work area outline
  const renderWorkAreaOutline = (workArea) => {
    if (!workArea || !workArea.element_ids || !lightweightData || !displaySettings.showWorkAreaBoundaries) return null;

    // Find all elements in this work area
    const elements = lightweightData.elements.filter(el =>
      workArea.element_ids.includes(el.id)
    );

    if (elements.length === 0) return null;

    // Calculate bounding box for all elements
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    elements.forEach(element => {
      if (element.points) {
        element.points.forEach(point => {
          minX = Math.min(minX, point.x);
          minY = Math.min(minY, point.y);
          maxX = Math.max(maxX, point.x);
          maxY = Math.max(maxY, point.y);
        });
      }

      if (element.center) {
        const radius = element.radius || 0;
        minX = Math.min(minX, element.center.x - radius);
        minY = Math.min(minY, element.center.y - radius);
        maxX = Math.max(maxX, element.center.x + radius);
        maxY = Math.max(maxY, element.center.y + radius);
      }

      if (element.position) {
        minX = Math.min(minX, element.position.x);
        minY = Math.min(minY, element.position.y);
        maxX = Math.max(maxX, element.position.x);
        maxY = Math.max(maxY, element.position.y);
      }
    });

    // Add padding
    const padding = 5;
    minX -= padding;
    minY -= padding;
    maxX += padding;
    maxY += padding;

    // Determine color based on status
    const color = getWorkAreaColor(workArea);
    const isSelected = selectedWorkArea && selectedWorkArea.id === workArea.id;

    // Style based on selection state and status
    const rectStyle = {
      stroke: color,
      strokeWidth: isSelected ? 2 / scale : 1 / scale,
      dash: [5 / scale, 5 / scale],
      fill: color,
      opacity: isSelected ? 0.3 : 0.1,
    };

    return (
      <Group 
        key={`work-area-${workArea.id}`} 
        onClick={(e) => {
          // Остановка распространения события
          e.cancelBubble = true;
          handleWorkAreaClick(workArea, e);
        }}
        onTap={(e) => {
          e.cancelBubble = true;
          handleWorkAreaClick(workArea, e);
        }}
      >
        <Rect
          x={minX}
          y={minY}
          width={maxX - minX}
          height={maxY - minY}
          {...rectStyle}
        />
        {/* Label for the work area */}
        <Text
          x={minX + (maxX - minX) / 2}
          y={minY - 15 / scale}
          text={workArea.name}
          fontSize={12 / scale}
          fill={color}
          align="center"
        />
      </Group>
    );
  };

  // Zoom controls
  const zoomIn = () => {
    setScale(prev => Math.min(100, prev * 1.2));
  };

  const zoomOut = () => {
    setScale(prev => Math.max(0.01, prev / 1.2));
  };

  // Reset view
  const resetView = () => {
    fitDrawingToViewport();
  };

  // Toggle info drawer
  const toggleInfoDrawer = () => {
    setInfoDrawerOpen(!infoDrawerOpen);
  };

  // Handler for tab change in info drawer
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle notification close
  const handleCloseNotification = () => {
    setNotification(null);
  };

  // Loading state
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Loading drawing data...
        </Typography>
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box sx={{ m: 2 }}>
        <Alert severity="error">{error}</Alert>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Box>
    );
  }

  // No drawing data
  if (!drawing || !lightweightData) {
    return (
      <Box sx={{ m: 2 }}>
        <Alert severity="info">No drawing data available. Please select a different file.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 'calc(100vh - 120px)', display: 'flex', flexDirection: 'column' }}>
      {/* Toolbar */}
      <Box ref={toolbarRef}>
        <ViewerToolbar
          drawingName={drawing.name || drawing.filename || 'Untitled Drawing'}
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
          onPan={() => setIsPanning(!isPanning)}
          onReset={resetView}
          onToggleLayers={toggleInfoDrawer}
          onShowInfo={toggleInfoDrawer}
          onToggleSelection={() => toggleSelectionMode('element')}
          onToggleSegmentSelection={() => toggleSelectionMode('segment')}
          isPanning={isPanning}
          isSelectionMode={selectionMode === 'element'}
          isSegmentSelectionMode={selectionMode === 'segment'}
          showNonWorkAreaElements={displaySettings.showNonWorkAreaElements}
          onToggleNonWorkAreaElements={toggleNonWorkAreaElements}
          metrics={{
            completedPercentage: calculateMetrics.completedPercentage,
            progress: {
              completed_count: calculateMetrics.completedCount,
              in_progress_count: calculateMetrics.inProgressCount,
              delayed_count: calculateMetrics.delayedCount,
              not_started_count: calculateMetrics.notStartedCount
            }
          }}
        />
      </Box>

      {/* Main content */}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {/* Left sidebar - Work Areas */}
        <Grid item xs={12} md={3} lg={2.5}>
          <Paper variant="outlined" sx={{ p: 2, height: '100%', overflow: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Work Areas
              <Chip
                label={lightweightData.workAreas?.length || 0}
                color="primary"
                size="small"
                sx={{ ml: 1 }}
              />
            </Typography>

            {/* Filters */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Filter by Status
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {Object.entries(STATUS_LABELS).map(([status, label]) => (
                  <Chip
                    key={status}
                    label={label}
                    size="small"
                    color={filterByStatus === status ? 'primary' : 'default'}
                    onClick={() => toggleStatusFilter(status)}
                    sx={{
                      bgcolor: filterByStatus === status ? undefined : 'transparent',
                      borderColor: STATUS_COLORS[status],
                      '& .MuiChip-label': {
                        color: filterByStatus === status ? undefined : STATUS_COLORS[status]
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Filter by Category
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {workAreaCategories.map(category => (
                  <Chip
                    key={category}
                    label={category}
                    size="small"
                    color={filterByCategory === category ? 'primary' : 'default'}
                    onClick={() => toggleCategoryFilter(category)}
                  />
                ))}
              </Box>
            </Box>
            
            {/* Selection Mode */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Selection Mode
              </Typography>
              <ToggleButtonGroup
                value={selectionMode}
                exclusive
                onChange={(e, mode) => mode && toggleSelectionMode(mode)}
                size="small"
                fullWidth
              >
                <ToggleButton value="element">
                  <Tooltip title="Select Entire Elements">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <BrushOutlined fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="body2">Element</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton 
                  value="segment"
                  disabled={!displaySettings.enableSegmentSelection}
                >
                  <Tooltip title="Select Individual Segments">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ContentCut fontSize="small" sx={{ mr: 0.5 }} />
                      <Typography variant="body2">Segment</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            
            {/* Segment Selection Mode (if segment mode active) */}
            {selectionMode === 'segment' && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Segment Selection
                </Typography>
                <ToggleButtonGroup
                  value={displaySettings.segmentSelectionMode}
                  exclusive
                  onChange={(e, mode) => mode && changeSegmentSelectionMode(mode)}
                  size="small"
                  fullWidth
                >
                  <ToggleButton value="single">
                    <Tooltip title="Select One Segment at a Time">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HighlightAlt fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">Single</Typography>
                      </Box>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="multiple">
                    <Tooltip title="Select Multiple Segments">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StraightenOutlined fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">Multiple</Typography>
                      </Box>
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
                
                {/* Show selected segments count and update button */}
                {selectedSegments.length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    <Alert severity="info" sx={{ mb: 1 }}>
                      {selectedSegments.length} segment{selectedSegments.length !== 1 ? 's' : ''} selected
                    </Alert>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={openSegmentUpdateDialog}
                    >
                      Update Selected Segments
                    </Button>
                  </Box>
                )}
              </Box>
            )}

            <Divider sx={{ my: 2 }} />

            {/* Work Areas List */}
            <List dense sx={{ maxHeight: 'calc(100vh - 400px)', overflow: 'auto' }}>
              {filteredWorkAreas.map(workArea => (
                <ListItem
                  key={workArea.id}
                  button
                  onClick={() => handleWorkAreaClick(workArea)}
                  selected={selectedWorkArea && selectedWorkArea.id === workArea.id}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        bgcolor: STATUS_COLORS[workArea.status] || STATUS_COLORS.not_started
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={workArea.name}
                    secondary={
                      <>
                        <Chip
                          label={workArea.category}
                          size="small"
                          variant="outlined"
                          sx={{ mr: 1 }}
                        />
                        <Chip
                          label={`${Math.round(workArea.progress || 0)}%`}
                          size="small"
                          color={workArea.progress >= 100 ? 'success' :
                            workArea.progress > 0 ? 'primary' : 'default'}
                        />
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>

            {filteredWorkAreas.length === 0 && (
              <Alert severity="info" sx={{ mt: 2 }}>
                No work areas match the current filters.
              </Alert>
            )}

            {/* Progress Summary */}
            <Box sx={{ mt: 2 }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="subtitle2" gutterBottom>
                    Project Progress
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={calculateMetrics.completedPercentage}
                    sx={{ height: 10, borderRadius: 1, mb: 2 }}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary">
                        Completed:
                      </Typography>
                      <Typography variant="body2">
                        {calculateMetrics.completedCount} of {calculateMetrics.totalCount} areas
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="text.secondary">
                        In Progress:
                      </Typography>
                      <Typography variant="body2">
                        {calculateMetrics.inProgressCount} areas
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Paper>
        </Grid>

        {/* Drawing canvas */}
        <Grid item xs={12} md={9} lg={9.5} ref={containerRef} sx={{ height: '100%' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              border: '1px solid #ddd',
              borderRadius: 1,
              overflow: 'hidden',
              bgcolor: '#f5f5f5',
              cursor: isPanning ? 'grab' : 
                    selectionMode === 'element' ? 'cell' :
                    selectionMode === 'segment' ? 'crosshair' : 'default'
            }}
          >
            <Stage
              ref={stageRef}
              width={viewportSize.width}
              height={viewportSize.height}
              onWheel={handleWheel}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              draggable={false}
            >
              <Layer>
                <Group x={position.x} y={position.y} scaleX={scale} scaleY={scale}>
                  {/* Render work area outlines first (as background) */}
                  {filteredWorkAreas.map(renderWorkAreaOutline)}

                  {/* Render visible elements */}
                  {visibleElements.map(renderElement)}
                </Group>
              </Layer>
            </Stage>

            {/* Zoom controls */}
            <Box sx={{
              position: 'absolute',
              bottom: 12,
              right: 12,
              display: 'flex',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: 1,
              p: 0.5
            }}>
              <Tooltip title="Zoom In">
                <IconButton size="small" onClick={zoomIn}>
                  <ZoomIn fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Zoom Out">
                <IconButton size="small" onClick={zoomOut}>
                  <ZoomOut fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Fit Drawing">
                <IconButton size="small" onClick={fitDrawingToViewport}>
                  <CropFree fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            
            {/* Display mode controls */}
            <Box sx={{
              position: 'absolute',
              top: 12,
              left: 12,
              display: 'flex',
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              borderRadius: 1,
              p: 0.5
            }}>
              <Tooltip title={displaySettings.showNonWorkAreaElements ? 
                "Hide Elements Outside Work Areas" : 
                "Show All Elements"}>
                <IconButton 
                  size="small" 
                  onClick={toggleNonWorkAreaElements}
                  color={displaySettings.showNonWorkAreaElements ? "primary" : "default"}
                >
                  {displaySettings.showNonWorkAreaElements ? 
                    <Visibility fontSize="small" /> : 
                    <VisibilityOff fontSize="small" />}
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Selection Mode: Element">
                <IconButton 
                  size="small" 
                  onClick={() => toggleSelectionMode('element')}
                  color={selectionMode === 'element' ? "primary" : "default"}
                >
                  <BrushOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Selection Mode: Segment">
                <IconButton 
                  size="small" 
                  onClick={() => toggleSelectionMode('segment')}
                  color={selectionMode === 'segment' ? "primary" : "default"}
                  disabled={!displaySettings.enableSegmentSelection}
                >
                  <ContentCut fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Info drawer */}
      <Drawer
        anchor="right"
        open={infoDrawerOpen}
        onClose={() => setInfoDrawerOpen(false)}
        sx={{ '& .MuiDrawer-paper': { width: 400 } }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h6">Project Information</Typography>
          <IconButton onClick={() => setInfoDrawerOpen(false)}>
            <Close />
          </IconButton>
        </Box>

        <Divider />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab icon={<Info />} label="Details" />
            <Tab icon={<Work />} label="Progress" />
            <Tab icon={<AttachMoney />} label="Costs" />
          </Tabs>
        </Box>

        {/* Details Tab */}
        <Box
          role="tabpanel"
          hidden={activeTab !== 0}
          sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
        >
          {activeTab === 0 && (
            <>
              {selectedWorkArea ? (
                <Box>
                  <Typography variant="h6" color="primary">
                    {selectedWorkArea.name}
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                    <Chip
                      label={selectedWorkArea.category}
                      size="small"
                      variant="outlined"
                      sx={{ mr: 1 }}
                    />
                    <Chip
                      label={STATUS_LABELS[selectedWorkArea.status]}
                      size="small"
                      sx={{
                        bgcolor: STATUS_COLORS[selectedWorkArea.status],
                        color: '#fff'
                      }}
                    />
                  </Box>

                  {selectedWorkArea.description && (
                    <Typography variant="body2" paragraph>
                      {selectedWorkArea.description}
                    </Typography>
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="subtitle1" gutterBottom>
                    Real Dimensions
                  </Typography>

                  {selectedWorkArea.real_dimensions ? (
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableBody>
                          {selectedWorkArea.real_dimensions.length && (
                            <TableRow>
                              <TableCell>Length</TableCell>
                              <TableCell align="right">
                                {selectedWorkArea.real_dimensions.length} {selectedWorkArea.real_dimensions.unit}
                              </TableCell>
                            </TableRow>
                          )}
                          {selectedWorkArea.real_dimensions.area && (
                            <TableRow>
                              <TableCell>Area</TableCell>
                              <TableCell align="right">
                                {selectedWorkArea.real_dimensions.area} {selectedWorkArea.real_dimensions.unit}²
                              </TableCell>
                            </TableRow>
                          )}
                          {selectedWorkArea.real_dimensions.unit && (
                            <TableRow>
                              <TableCell>Unit</TableCell>
                              <TableCell align="right">
                                {selectedWorkArea.real_dimensions.unit}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Alert severity="warning">
                      No real dimensions specified. Consider reconfiguring this work area.
                    </Alert>
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="subtitle1" gutterBottom>
                    Work Types
                  </Typography>

                  {selectedWorkArea.work_types && selectedWorkArea.work_types.length > 0 ? (
                    <TableContainer component={Paper} variant="outlined">
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Work Type</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Completed</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedWorkArea.work_types.map(wt => {
                            const workType = getWorkTypeById(wt.work_type_id);
                            return (
                              <TableRow key={wt.work_type_id}>
                                <TableCell>{workType?.name || 'Unknown'}</TableCell>
                                <TableCell align="right">
                                  {wt.quantity} {workType?.unit || ''}
                                </TableCell>
                                <TableCell align="right">
                                  {wt.completed_quantity || 0} {workType?.unit || ''}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Alert severity="info">
                      No work types assigned to this work area.
                    </Alert>
                  )}

                  <Divider sx={{ my: 2 }} />

                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => openStatusDialog(selectedWorkArea)}
                    >
                      Update Status
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => openProgressDialog(selectedWorkArea)}
                      disabled={!selectedWorkArea.work_types || selectedWorkArea.work_types.length === 0}
                    >
                      Update Progress
                    </Button>
                  </Box>
                </Box>
              ) : selectedElement ? (
                <Box>
                  <Typography variant="h6" color="primary">
                    Element Details
                  </Typography>

                  <TableContainer component={Paper} variant="outlined" sx={{ my: 2 }}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>{selectedElement.id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>{selectedElement.type}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Layer</TableCell>
                          <TableCell>{selectedElement.layer}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Status</TableCell>
                          <TableCell>
                            <Chip
                              label={STATUS_LABELS[selectedElement.status || 'not_started']}
                              size="small"
                              sx={{
                                bgcolor: STATUS_COLORS[selectedElement.status || 'not_started'],
                                color: '#fff'
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        {selectedElement.metrics && selectedElement.metrics.length && (
                          <TableRow>
                            <TableCell>Length</TableCell>
                            <TableCell>{selectedElement.metrics.length.toFixed(2)}</TableCell>
                          </TableRow>
                        )}
                        {selectedElement.metrics && selectedElement.metrics.area && (
                          <TableRow>
                            <TableCell>Area</TableCell>
                            <TableCell>{selectedElement.metrics.area.toFixed(2)}</TableCell>
                          </TableRow>
                        )}
                        {selectedElement.workAreaId && (
                          <TableRow>
                            <TableCell>Work Area</TableCell>
                            <TableCell>
                              {lightweightData.workAreas.find(
                                wa => wa.id === selectedElement.workAreaId
                              )?.name || 'Unknown'}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Segments</TableCell>
                          <TableCell>{selectedElement.segments?.length || 0}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {selectedElement.realMetrics && (
                    <Box>
                      <Typography variant="subtitle1" gutterBottom>
                        Real-World Metrics
                      </Typography>
                      <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                          <TableBody>
                            {selectedElement.realMetrics.length && (
                              <TableRow>
                                <TableCell>Real Length</TableCell>
                                <TableCell>
                                  {selectedElement.realMetrics.length.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )}
                            {selectedElement.realMetrics.area && (
                              <TableRow>
                                <TableCell>Real Area</TableCell>
                                <TableCell>
                                  {selectedElement.realMetrics.area.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                  
                  {/* Segments section */}
                  {selectedElement.segments && selectedElement.segments.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Segments
                      </Typography>
                      <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 300, overflow: 'auto' }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Segment ID</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell align="right">Completion</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedElement.segments.map(segment => (
                              <TableRow 
                                key={segment.id}
                                sx={{ 
                                  bgcolor: isSegmentSelected(selectedElement.id, segment.id) ? 
                                    'rgba(25, 118, 210, 0.1)' : 'inherit',
                                  '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                                }}
                                onClick={() => {
                                  if (selectionMode === 'segment') {
                                    handleSegmentClick(selectedElement, segment);
                                  }
                                }}
                              >
                                <TableCell>{segment.id.split('_').pop()}</TableCell>
                                <TableCell>
                                  <Chip
                                    label={STATUS_LABELS[segment.status || 'not_started']}
                                    size="small"
                                    sx={{
                                      bgcolor: STATUS_COLORS[segment.status || 'not_started'],
                                      color: '#fff'
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  {segment.completion || 0}%
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      
                      {/* Option to update segments */}
                      {selectionMode === 'segment' && selectedSegments.length > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          fullWidth
                          sx={{ mt: 1 }}
                          onClick={openSegmentUpdateDialog}
                        >
                          Update Selected Segments
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography variant="h6" color="primary">
                    {drawing.name || drawing.filename || 'Untitled Drawing'}
                  </Typography>

                  <Typography variant="body2" paragraph>
                    {drawing.description || 'No description available.'}
                  </Typography>

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="subtitle1" gutterBottom>
                    Project Summary
                  </Typography>

                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>Work Areas</TableCell>
                          <TableCell>{lightweightData.workAreas?.length || 0}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Work Types</TableCell>
                          <TableCell>{lightweightData.workTypes?.length || 0}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Elements</TableCell>
                          <TableCell>{lightweightData.elements?.length || 0}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Overall Progress</TableCell>
                          <TableCell>{calculateMetrics.completedPercentage}%</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box sx={{ mt: 2 }}>
                    <Alert severity="info">
                      Select a work area from the sidebar or click on elements in the drawing to see detailed information.
                    </Alert>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        
        {/* Progress Tab */}
        <Box
          role="tabpanel"
          hidden={activeTab !== 1}
          sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
        >
          {activeTab === 1 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Project Progress
              </Typography>

              <LinearProgress
                variant="determinate"
                value={calculateMetrics.completedPercentage}
                sx={{ height: 10, borderRadius: 1, mb: 2 }}
              />

              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={6}>
                  <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                    <Typography variant="h5" color="primary">
                      {calculateMetrics.completedPercentage}%
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Overall Completion
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                    <Typography variant="h5" color="primary">
                      {calculateMetrics.completedCount} / {calculateMetrics.totalCount}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Work Areas Completed
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" gutterBottom>
                Status Breakdown
              </Typography>

              <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Count</TableCell>
                      <TableCell align="right">Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              bgcolor: STATUS_COLORS.completed,
                              mr: 1
                            }}
                          />
                          Completed
                        </Box>
                      </TableCell>
                      <TableCell align="right">{calculateMetrics.completedCount}</TableCell>
                      <TableCell align="right">
                        {calculateMetrics.totalCount
                          ? Math.round((calculateMetrics.completedCount / calculateMetrics.totalCount) * 100)
                          : 0}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              bgcolor: STATUS_COLORS.in_progress,
                              mr: 1
                            }}
                          />
                          In Progress
                        </Box>
                      </TableCell>
                      <TableCell align="right">{calculateMetrics.inProgressCount}</TableCell>
                      <TableCell align="right">
                        {calculateMetrics.totalCount
                          ? Math.round((calculateMetrics.inProgressCount / calculateMetrics.totalCount) * 100)
                          : 0}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              bgcolor: STATUS_COLORS.delayed,
                              mr: 1
                            }}
                          />
                          Delayed
                        </Box>
                      </TableCell>
                      <TableCell align="right">{calculateMetrics.delayedCount}</TableCell>
                      <TableCell align="right">
                        {calculateMetrics.totalCount
                          ? Math.round((calculateMetrics.delayedCount / calculateMetrics.totalCount) * 100)
                          : 0}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              bgcolor: STATUS_COLORS.not_started,
                              mr: 1
                            }}
                          />
                          Not Started
                        </Box>
                      </TableCell>
                      <TableCell align="right">{calculateMetrics.notStartedCount}</TableCell>
                      <TableCell align="right">
                        {calculateMetrics.totalCount
                          ? Math.round((calculateMetrics.notStartedCount / calculateMetrics.totalCount) * 100)
                          : 0}%
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="subtitle1" gutterBottom>
                Work Areas by Status
              </Typography>

              <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 250, overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell align="right">Progress</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lightweightData.workAreas
                      ?.sort((a, b) => (b.progress || 0) - (a.progress || 0))
                      .map(workArea => (
                        <TableRow
                          key={workArea.id}
                          hover
                          onClick={() => handleWorkAreaClick(workArea)}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'action.hover' }
                          }}
                        >
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                                sx={{
                                  width: 12,
                                  height: 12,
                                  borderRadius: '50%',
                                  bgcolor: STATUS_COLORS[workArea.status] || '#888',
                                  mr: 1
                                }}
                              />
                              {workArea.name}
                            </Box>
                          </TableCell>
                          <TableCell>{workArea.category}</TableCell>
                          <TableCell align="right">
                            <Typography variant="body2">
                              {Math.round(workArea.progress || 0)}%
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>

        {/* Costs Tab */}
        <Box
          role="tabpanel"
          hidden={activeTab !== 2}
          sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}
        >
          {activeTab === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Project Costs
              </Typography>

              {costReport ? (
                <>
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    <Grid item xs={4}>
                      <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary">
                          ${costReport.total_cost.toFixed(2)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Total Cost
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary">
                          ${costReport.total_payment.toFixed(2)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Payments
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={4}>
                      <Paper variant="outlined" sx={{ p: 1.5, textAlign: 'center' }}>
                        <Typography variant="h6" color="success.main">
                          ${costReport.total_profit.toFixed(2)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Profit
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                  <Typography variant="subtitle1" gutterBottom>
                    Costs by Category
                  </Typography>

                  <TableContainer component={Paper} variant="outlined" sx={{ mb: 3 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Category</TableCell>
                          <TableCell align="right">Cost</TableCell>
                          <TableCell align="right">Percentage</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(costReport.by_category || {})
                          .sort(([, a], [, b]) => b.cost - a.cost)
                          .map(([category, data]) => (
                            <TableRow key={category}>
                              <TableCell>{category}</TableCell>
                              <TableCell align="right">
                                ${data.cost.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {costReport.total_cost > 0
                                  ? ((data.cost / costReport.total_cost) * 100).toFixed(1)
                                  : 0}%
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Typography variant="subtitle1" gutterBottom>
                    Costs by Work Type
                  </Typography>

                  <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 250, overflow: 'auto' }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Work Type</TableCell>
                          <TableCell align="right">Total Cost</TableCell>
                          <TableCell align="right">Profit</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(costReport.by_work_type || {})
                          .sort(([, a], [, b]) => b.cost - a.cost)
                          .map(([id, data]) => (
                            <TableRow key={id}>
                              <TableCell>
                                <Tooltip title={`Category: ${data.category}`}>
                                  <Typography variant="body2">
                                    {data.name}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="right">
                                ${data.cost.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  variant="body2"
                                  color={data.profit > 0 ? 'success.main' : 'error.main'}
                                >
                                  ${data.profit.toFixed(2)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Alert severity="info">
                  Cost data is not available for this drawing.
                </Alert>
              )}
            </Box>
          )}
        </Box>
      </Drawer>

      {/* Work Area Status Dialog */}
      <Dialog
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Update Work Area Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change the status of work area "{selectedWorkArea?.name}" to:
          </DialogContentText>

          <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button
              variant="contained"
              color="success"
              startIcon={<DoneAll />}
              onClick={() => handleWorkAreaStatusChange(selectedWorkArea?.id, 'completed')}
              disabled={selectedWorkArea?.status === 'completed'}
            >
              Completed
            </Button>

            <Button
              variant="contained"
              color="primary"
              startIcon={<Pending />}
              onClick={() => handleWorkAreaStatusChange(selectedWorkArea?.id, 'in_progress')}
              disabled={selectedWorkArea?.status === 'in_progress'}
            >
              In Progress
            </Button>

            <Button
              variant="contained"
              color="warning"
              startIcon={<Warning />}
              onClick={() => handleWorkAreaStatusChange(selectedWorkArea?.id, 'delayed')}
              disabled={selectedWorkArea?.status === 'delayed'}
            >
              Delayed
            </Button>

            <Button
              variant="contained"
              color="inherit"
              startIcon={<DoNotDisturbAlt />}
              onClick={() => handleWorkAreaStatusChange(selectedWorkArea?.id, 'not_started')}
              disabled={selectedWorkArea?.status === 'not_started'}
            >
              Not Started
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Work Type Progress Dialog */}
      <Dialog
        open={progressDialogOpen}
        onClose={() => setProgressDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Update Work Progress
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the progress for work types in area "{selectedWorkArea?.name}":
          </DialogContentText>

          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="work-type-select-label">Work Type</InputLabel>
              <Select
                labelId="work-type-select-label"
                value={progressForm.workTypeId}
                label="Work Type"
                onChange={(e) => {
                  const workTypeId = e.target.value;
                  const workType = selectedWorkArea?.work_types?.find(
                    wt => wt.work_type_id === workTypeId
                  );

                  setProgressForm({
                    workTypeId,
                    completedQuantity: workType?.completed_quantity || 0
                  });
                }}
              >
                {selectedWorkArea?.work_types?.map(wt => {
                  const workType = getWorkTypeById(wt.work_type_id);
                  return (
                    <MenuItem key={wt.work_type_id} value={wt.work_type_id}>
                      {workType?.name || 'Unknown'}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {progressForm.workTypeId && (
              <>
                <Box sx={{ mb: 2 }}>
                  {(() => {
                    const workType = getWorkTypeById(progressForm.workTypeId);
                    const assignedWorkType = selectedWorkArea?.work_types?.find(
                      wt => wt.work_type_id === progressForm.workTypeId
                    );

                    if (!workType || !assignedWorkType) return null;

                    const totalQuantity = assignedWorkType.quantity || 0;
                    const currentProgress = (assignedWorkType.completed_quantity || 0) / totalQuantity * 100;

                    return (
                      <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            Total Quantity: {totalQuantity} {workType.unit}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Current Progress: {currentProgress.toFixed(1)}%
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={currentProgress}
                          sx={{ height: 8, borderRadius: 1, mb: 2 }}
                        />
                      </>
                    );
                  })()}</Box>

                <TextField
                  fullWidth
                  label="Completed Quantity"
                  type="number"
                  value={progressForm.completedQuantity}
                  onChange={(e) => setProgressForm({
                    ...progressForm,
                    completedQuantity: e.target.value
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {getWorkTypeById(progressForm.workTypeId)?.unit || ''}
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setProgressDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleWorkTypeProgressUpdate}
            variant="contained"
            color="primary"
            disabled={!progressForm.workTypeId || !progressForm.completedQuantity}
          >
            Update Progress
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Segment Status Update Dialog */}
      <Dialog
        open={segmentDialogOpen}
        onClose={() => setSegmentDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Update Segment{selectedSegments.length > 1 ? 's' : ''} Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update status for {selectedSegments.length} selected segment{selectedSegments.length > 1 ? 's' : ''}:
          </DialogContentText>
          
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="segment-status-label">Status</InputLabel>
              <Select
                labelId="segment-status-label"
                value={segmentUpdateForm.status}
                label="Status"
                onChange={(e) => setSegmentUpdateForm({
                  ...segmentUpdateForm,
                  status: e.target.value,
                  completion: e.target.value === 'completed' ? 100 : segmentUpdateForm.completion
                })}
              >
                {Object.entries(STATUS_LABELS).map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box 
                        sx={{ 
                          width: 16, 
                          height: 16, 
                          borderRadius: '50%', 
                          bgcolor: STATUS_COLORS[value],
                          mr: 1
                        }} 
                      />
                      {label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <TextField
              fullWidth
              label="Completion Percentage"
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={segmentUpdateForm.completion}
              onChange={(e) => {
                const value = Math.min(100, Math.max(0, parseInt(e.target.value) || 0));
                setSegmentUpdateForm({
                  ...segmentUpdateForm,
                  completion: value,
                  // Auto-update status based on completion
                  status: value === 100 ? 'completed' : 
                         value > 0 ? 'in_progress' : 'not_started'
                });
              }}
              disabled={segmentUpdateForm.status === 'completed'}
              helperText={segmentUpdateForm.status === 'completed' ? 'Completion is always 100% for completed items' : ''}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSegmentDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSegmentStatusUpdate}
            variant="contained"
            color="primary"
          >
            Update Status
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notifications */}
      <Snackbar
        open={!!notification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification?.type || 'info'}
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DxfViewer;
