import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress,
    Paper,
    Alert,
    Snackbar
} from '@mui/material';

import LayerSelector from './configuration/LayerSelector';
import WorkAreaDefiner from './configuration/WorkAreaDefiner';
import WorkTypeManager from './configuration/WorkTypeManager';
import ConfigurationSummary from './configuration/ConfigurationSummary';

import { fetchDrawing } from '../services/api';
import {
    configureDrawingLayers,
    configureDrawingWorkAreas,
    configureDrawingWorkTypes,
    finalizeDrawingConfiguration
} from '../services/configurationApi';

const steps = ['Select Layers', 'Define Work Areas', 'Define Work Types', 'Review & Finalize'];

const ConfigurationWizard = () => {
    const { fileId } = useParams();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [drawing, setDrawing] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState(null);

    // Configuration state
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [workAreas, setWorkAreas] = useState([]);
    const [workTypes, setWorkTypes] = useState([]);

    // Load drawing data
    useEffect(() => {
        const loadDrawing = async () => {
            try {
                setLoading(true);
                const data = await fetchDrawing(fileId);
                setDrawing(data);

                // Initialize layers from drawing metadata
                if (data.metadata && data.metadata.layers) {
                    // Set initially visible layers as selected
                    const initialLayers = data.metadata.layers
                        .filter(layer => layer.is_on && !layer.is_frozen)
                        .map(layer => layer.name);
                    setSelectedLayers(data.selected_layers || initialLayers);
                }

                // Check if drawing already has configuration and set initial states
                if (data.selected_layers) {
                    setSelectedLayers(data.selected_layers);
                }
                if (data.work_areas) {
                    setWorkAreas(data.work_areas);
                }
                if (data.work_types) {
                    setWorkTypes(data.work_types);
                }

                // Set active step based on configuration progress
                if (data.configuration_step === 'layers_selected') {
                    setActiveStep(1);
                } else if (data.configuration_step === 'work_areas_defined') {
                    setActiveStep(2);
                } else if (data.configuration_step === 'work_types_defined') {
                    setActiveStep(3);
                } else if (data.is_configured) {
                    // Drawing is already configured, redirect to viewer
                    navigate(`/viewer/${fileId}`);
                    return;
                }

                setLoading(false);
            } catch (err) {
                console.error('Error loading drawing:', err);
                setError('Failed to load drawing. Please try again.');
                setLoading(false);
            }
        };

        loadDrawing();
    }, [fileId, navigate]);

    const handleNext = async () => {
        // Validate current step
        if (activeStep === 0 && selectedLayers.length === 0) {
            setNotification({ type: 'error', message: 'Please select at least one layer to continue.' });
            return;
        }

        if (activeStep === 1 && workAreas.length === 0) {
            setNotification({ type: 'error', message: 'Please define at least one work area to continue.' });
            return;
        }

        if (activeStep === 2 && workTypes.length === 0) {
            setNotification({ type: 'error', message: 'Please define at least one work type to continue.' });
            return;
        }

        try {
            setSaving(true);
            // Save current step data
            if (activeStep === 0) {
                await configureDrawingLayers(fileId, { selected_layers: selectedLayers });
                setNotification({ type: 'success', message: 'Layers configuration saved successfully.' });
            } else if (activeStep === 1) {
                await configureDrawingWorkAreas(fileId, workAreas);
                setNotification({ type: 'success', message: 'Work areas configuration saved successfully.' });
            } else if (activeStep === 2) {
                await configureDrawingWorkTypes(fileId, workTypes);
                setNotification({ type: 'success', message: 'Work types configuration saved successfully.' });
            } else if (activeStep === 3) {
                // Finalize configuration
                const result = await finalizeDrawingConfiguration(fileId);
                setNotification({
                    type: 'success',
                    message: `Configuration finalized! Original entities: ${result.metrics.original_entity_count}, 
                    Lightweight entities: ${result.metrics.lightweight_entity_count}`
                });

                // Redirect to viewer after a brief delay to show the success message
                setTimeout(() => {
                    navigate(`/viewer/${fileId}`);
                }, 1500);
                return;
            }

            setSaving(false);
            setActiveStep(prevStep => prevStep + 1);
        } catch (err) {
            console.error('Error saving configuration:', err);
            setSaving(false);
            setError(`Failed to save configuration: ${err.message}`);
        }
    };

    const handleBack = () => {
        setActiveStep(prevStep => prevStep - 1);
    };

    const handleCloseNotification = () => {
        setNotification(null);
    };

    // Render content based on current step
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <LayerSelector
                        drawing={drawing}
                        selectedLayers={selectedLayers}
                        setSelectedLayers={setSelectedLayers}
                    />
                );
            case 1:
                return (
                    <WorkAreaDefiner
                        drawing={drawing}
                        selectedLayers={selectedLayers}
                        workAreas={workAreas}
                        setWorkAreas={setWorkAreas}
                    />
                );
            case 2:
                return (
                    <WorkTypeManager
                        workAreas={workAreas}
                        workTypes={workTypes}
                        setWorkTypes={setWorkTypes}
                    />
                );
            case 3:
                return (
                    <ConfigurationSummary
                        drawing={drawing}
                        selectedLayers={selectedLayers}
                        workAreas={workAreas}
                        workTypes={workTypes}
                    />
                );
            default:
                return 'Unknown step';
        }
    };

    // Show loading state
    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
                <Typography variant="h6" sx={{ ml: 2 }}>
                    Loading drawing data...
                </Typography>
            </Box>
        );
    }

    // Show error state
    if (error) {
        return (
            <Box sx={{ m: 2 }}>
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
                <Button
                    variant="contained"
                    onClick={() => window.location.reload()}
                >
                    Retry
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', p: 3 }}>
            <Paper sx={{ p: 3, mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Configure Drawing: {drawing?.name || drawing?.filename || 'Untitled Drawing'}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    This wizard will guide you through configuring your drawing for optimal performance.
                    Each step helps reduce the data size while preserving the important elements needed for your project.
                </Typography>
            </Paper>

            <Stepper activeStep={activeStep} sx={{ mb: 4 }} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper sx={{ p: 3 }}>
                {getStepContent(activeStep)}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button
                        disabled={activeStep === 0 || saving}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={saving}
                    >
                        {saving ? (
                            <>
                                <CircularProgress size={24} sx={{ mr: 1 }} />
                                Saving...
                            </>
                        ) : activeStep === steps.length - 1 ? (
                            'Finalize'
                        ) : (
                            'Next'
                        )}
                    </Button>
                </Box>
            </Paper>

            <Snackbar
                open={!!notification}
                autoHideDuration={5000}
                onClose={handleCloseNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification?.type || 'info'}
                    sx={{ width: '100%' }}
                >
                    {notification?.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ConfigurationWizard;
